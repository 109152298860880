import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { Table } from 'src/libs/table';
import { MultirideTicketDo } from 'src/dos/multiride/multiride-ticket.do';
import { TicketMultiRidePriceFilterDto } from 'src/dtos/tickets-multiride/ticket-multiride-price-filter.dto';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PassDetailsViewDto } from 'src/dtos/tickets-multiride/pass-details-view.dto';
import { TicketMultiRidePeriodViewDto } from 'src/dtos/tickets-multiride/ticket-multiride-period-view.dto';
import { ThisReceiver } from '@angular/compiler';
import { TicketMultiRideCreateDto } from 'src/dtos/tickets-multiride/ticket-multiride-create.dto';
import { TicketMultiRideViewDto } from 'src/dtos/tickets-multiride/ticket-multiride-view.dto';
import { TicketMultiRidePriceViewDto } from 'src/dtos/tickets-multiride/ticket-multiride-price-view.dto';

@Component({
  selector: 'app-multiride-create',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModule, FormsModule],
  templateUrl: './multiride-create.page.html',
  styleUrls: ['./multiride-create.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    NgbPaginationConfig
  ]
})
export class MultirideCreatePage extends BaseImports implements OnInit, OnDestroy {
  passId!: string;
  passDetails: PassDetailsViewDto;//PassDetailsViewDto
  periods: TicketMultiRidePeriodViewDto[];//TicketMultiRidePeriodViewDto
  selectedPeriodId!: number;
  price: number;
  table: Table<TicketMultiRideViewDto>;
  subscriptions: Subscription[] = [];

  constructor(private injector: Injector) {
    super(injector);
    this.passDetails = new PassDetailsViewDto();
    this.periods = [];
    this.price = 0;
    this.table = new Table<TicketMultiRideViewDto>(1, 10);
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.passId = params['passId'];
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit() {
    if (this.passId == null || this.passId == undefined) {
      this.commonService.alertError("Not found PassId");
      this.routerService.navigate("multiride-passenger-list");
    }

    this.subscriptions.push(this.webapiMultirideService.getTicketMultiRidePeriods().subscribe((res: TicketMultiRidePeriodViewDto[]) => {
      this.periods = res;
      if (res.length > 0) {
        this.selectedPeriodId = res[0].Id;
      }
    }));

    this.subscriptions.push(this.webapiMultirideService.getPassDetails(this.passId).subscribe((res: PassDetailsViewDto) => {
      this.passDetails = res;
      this.table.setData(res.MultiRideTickets);
    }));

    this.getPrice();
  }

  getPrice() {
    var filter = new TicketMultiRidePriceFilterDto();
    filter.FromDate = new Date();
    filter.PassId = this.passId;
    this.subscriptions.push(this.webapiMultirideService.getPassPrice(filter).subscribe((res: TicketMultiRidePriceViewDto) => {
      this.price = res.Price;
    }));
  }

  buy() {
    if (this.selectedPeriodId == null) {
      this.commonService.alert(this.translateService.instant("monthly_ticket_period_warning"));
      return;
    }

    var selectedPeriod = this.periods.find(x => x.Id == this.selectedPeriodId);

    if (selectedPeriod == null || !selectedPeriod) {
      this.commonService.alert(this.translateService.instant("monthly_ticket_period_warning"));
      return;
    }

    var data: TicketMultiRideCreateDto = {
      PassId: this.passDetails.PassId,
      TicketMultiRidePeriodId: selectedPeriod.Id,
      OrderCode: ""
    };

    this.subscriptions.push(this.webapiMultirideService.create(data).subscribe((res) => {
      var ticket = new MultirideTicketDo();
      ticket.PassengerName = this.passDetails.PassengerName;
      ticket.Category = this.passDetails.PassengerType;
      ticket.PassId = this.passDetails.PassId;
      ticket.Route = this.passDetails.StartPoint + " - " + this.passDetails.EndPoint;
      ticket.Price = this.price;
      ticket.Zone = this.passDetails.Zone ?? '';
      ticket.PeriodId = selectedPeriod?.Id ?? 0;

      if (selectedPeriod) {
        ticket.FromDate = selectedPeriod?.ValidFrom;
        ticket.ToDate = selectedPeriod?.ValidTo;
      }

      ticket.Amount = res.Amount;
      ticket.ClientId = res.ClientId;
      ticket.Currency = res.Currency;
      ticket.FailUrl = res.FailUrl;
      ticket.Hash = res.Hash;
      ticket.HashAlgorithm = res.HashAlgorithm;
      ticket.Installment = res.Installment;
      ticket.Lang = res.Lang;
      ticket.OkUrl = res.OkUrl;
      ticket.OrderCode = res.OrderCode;
      ticket.OrderId = res.OrderId;
      ticket.Rnd = res.Rnd;
      ticket.Shopurl = res.Shopurl;
      ticket.StoreType = res.StoreType;
      ticket.TransactionType = res.TransactionType;

      this.storageService.setMultirideTicket(ticket);
      this.routerService.navigate("multiride-preview");
    }));
  }
}
