import { Component, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { ModalDo } from 'src/dos/modal.do';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'cmp-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseImports {
  constructor(private injector: Injector, private modalService: NgbModal) {
    super(injector);
  }
  getLanguage() {
    return this.localizationService.getLanguage().LanguageCode;
  }


  open(content: string, title: string = "") {
    var body: ModalDo = {
      content: content,
      title: title
    };

    const ref = this.modalService.open(ModalComponent, { size: 'xl', scrollable: true });
    ref.componentInstance.setText(body);
  }
}
