import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { NoticeViewDto } from 'src/dtos/common/notice-view.dto';
import { LoyaltyUserDto } from 'src/dtos/loyalty-user.dto';
import { PointDto } from 'src/dtos/points.dto';
import { WebApiBaseService } from './webapi-base.service';
//dtos

@Injectable()
export class WebApiCommonService extends WebApiBaseService {
    baseUrl = Constants.BASE_URL + "Common";
    constructor(private injector: Injector) {
        super(injector);
    }

    getPoints(): Observable<PointDto[]> {
        return this.http.get<PointDto[]>(this.baseUrl + "/Points", this.getHttpParams());
    }

    GetNotices(): Observable<NoticeViewDto> {
        return this.http.get<NoticeViewDto>(this.baseUrl + "/Notices", this.getHttpParams());
    }

    getLoyaltyUser(cardId: string): Observable<LoyaltyUserDto> {
        return this.http.get<LoyaltyUserDto>(Constants.BASE_URL + "Reservations" + "/LoyalyUser/" + cardId, this.getHttpParams());
    }
}