<div class="container mb-5 text-center">
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label class="d-none d-md-block">{{ "roundtrip_date" | translate}}</label>
            <div class="input-group">
                <span class="input-group-text">
                    <img src="../../assets/icons/calendar.svg" width="20" class="margin-right-5">
                </span>
                <input type="text" ngbDatepicker #d="ngbDatepicker" class="form-control ps-1 pe-5" [(ngModel)]="date"
                    [ngModelOptions]="{standalone: true}" (click)="d.toggle()" (dateSelect)="OnChangeDate()" readonly>
            </div>
        </div>
    </div>
    <div class="section col-12 col-md-6  my-3 py-4" *ngFor="let journey of datedJourneys.data">
        <div class="d-flex flex-row justify-content-between ">
            <departure-description-component class="flex-fill mx-1 mx-sm-3" [datedJourney]="mapDatedJourney(journey)">
            </departure-description-component>
            <input class="form-check-input mx-1 mx-sm-3 my-auto" name="RoundtripDatedJourneyId" type="radio"
                [value]="journey" [(ngModel)]="roundtripDatedJourney" (ngModelChange)="OnSelectDatedJourney($event)">
        </div>
    </div>
    <div class="text-center text-primary fw-bold mt-3 font-18" *ngIf="datedJourneys.count == 0 && !inProgress">
        {{'no_results_for_selected_date' | translate}}
    </div>
    <div class="d-none d-md-flex justify-content-center">
        <ngb-pagination *ngIf="datedJourneys.data.length > 0" [collectionSize]="datedJourneys.count"
            [(page)]="datedJourneys.page" (pageChange)="datedJourneys.refreshData()" />
    </div>
    <div class="d-flex d-md-none justify-content-center" *ngIf="datedJourneys.count !== datedJourneys.data.length">
        <button class="btn btn-primary mx-auto" (click)="datedJourneys.loadMore()">
            Ucitaj jos
        </button>
    </div>
</div>