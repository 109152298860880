import { Injectable, Injector, signal, WritableSignal } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { LanguageDo } from 'src/dos/language.do';
import { SharedService } from './shared.service';

@Injectable()
export class LocalizationService {
  key: string;
  public currentLanguage: WritableSignal<LanguageDo>;
  langs: LanguageDo[];
  translate: TranslateService;

  constructor(private injector: Injector,
    private sharedService: SharedService) {
    this.translate = injector.get(TranslateService);
    this.key = 'lang';
    this.langs = [
      {
        Abbreviation: "RS",
        Language: "Srpski",
        ImageUrl: "serbia.jpg",
        LanguageCode: "sr",
        LanguageCode_2: "sr-Latn"
      },
      {
        Abbreviation: "EN",
        Language: "English",
        ImageUrl: "england.jpg",
        LanguageCode: "en",
        LanguageCode_2: "en"
      }
    ];
    this.currentLanguage = signal<LanguageDo>(this.langs[0]);
    this.init();
  }

  init() {
    this.translate.addLangs(this.langs.map(lang => lang.LanguageCode));
    this.translate.setDefaultLang(this.getLanguage().LanguageCode);
    this.translate.use(this.getLanguage().LanguageCode);
    this.currentLanguage.set(this.getLanguage());

    this.sharedService.broadcast(Constants.EV_LANGUAGE_CHANGED);
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem(this.key, lang);
    this.currentLanguage.set(this.getLanguage());
    this.sharedService.broadcast(Constants.EV_LANGUAGE_CHANGED);
  }

  getLanguage(): LanguageDo {
    var lang = localStorage.getItem(this.key);
    if (lang == null) {
      lang = 'sr';
    }

    return this.langs.find(l => l.LanguageCode == lang) ?? this.langs[0];
  }
}
