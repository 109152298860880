<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <h3>{{ "do_you_want_to_make_purchase" | translate }}</h3>
    <div class="mt-3 d-flex justify-content-center input-white">
        <textarea class="mx-auto w-100" [rows]="5" maxlength="40" [(ngModel)]="note"></textarea>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary me-2" (click)="modal.close('NO')">
        {{ 'no' | translate }}
    </button>
    <button type="button" class="btn btn-primary ms-2" (click)="modal.close('YES')">{{ 'yes' | translate }}</button>
</div>