import { Component, Injector, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDo } from 'src/dos/modal.do';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'alert-component',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  isWarrning = false;
  isError = false;
  isInfo = false;
  text = "";

  constructor(public activeModal: NgbActiveModal) {
  }

  public warrning(text: string) {
    this.isWarrning = true;
    this.isError = false;
    this.isInfo = false;
    this.text = text; 
  }

  public error(text: string) {
    this.isWarrning = false;
    this.isError = true;
    this.isInfo = false;
    this.text = text;
  }

  public info(text: string) {
    this.isWarrning = false;
    this.isError = false;
    this.isInfo = false;
    this.text = text;
  }
}
