<div class="title">
    <h1>{{ "user_profile" | translate }}</h1>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="text-center margin-top-30">
    <div class="row justify-content-center">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "username" | translate}}</label>
            <input type="text" formControlName="Username" class="form-control" />
            <span class="error-messages" *ngIf="submitted && f(form, 'Username')">{{ "required_field" | translate
                }}</span>
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "name" | translate}}</label>
            <input type="text" formControlName="Name" class="form-control" />
            <span class="error-messages" *ngIf="submitted && f(form, 'Name')">{{ "required_field" | translate }}</span>
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>E-mail</label>
            <input type="email" formControlName="Email" class="form-control"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
            <span class="error-messages" *ngIf="submitted && f(form, 'Email')">{{ "required_field" | translate }}</span>
            <span class="error-messages" *ngIf="submitted && f(form, 'Email', 'pattern')">{{ "email_is_not_valid" |
                translate }}</span>
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "phone" | translate}}</label>
            <input type="text" formControlName="Phone" class="form-control" />
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "address" | translate}}</label>
            <input type="text" formControlName="Address" class="form-control" />
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "passport" | translate}}</label>
            <input type="text" formControlName="Passport" class="form-control" />
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "birth_date" | translate}}</label>
            <div class="input-group">
                <span class="input-group-text">
                    <img src="../../assets/icons/calendar.svg" width="20" class="margin-right-5">
                </span>
                <input type="text" ngbDatepicker #d="ngbDatepicker" class="form-control ps-1 pe-5"
                    formControlName="BirthDate" (click)="d.toggle()" readonly />
            </div>
        </div>
    </div>
    <div class="row justify-content-center margin-top-10" *ngIf="form.controls['LoyaltyCardId'].value">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "loyalty" | translate}}</label>
            <input type="text" formControlName="LoyaltyCardId" class="form-control" />
        </div>
    </div>
    <div class="row justify-content-center margin-top-10" *ngIf="form.controls['LoyaltyCardId'].value">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "current_balance_on_the_loyalty_card" | translate}}</label>
            <input type="text" formControlName="LoyaltyBalance" class="form-control" />
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="col-12 text-center">
            <a [routerLink]="['/change-password']">{{ "change_password" | translate }}</a>
        </div>
    </div>
    <div class="form-group row margin-top-30">
        <div class="col-6 text-right">
            <button type="button" class="btn btn-outline-primary" (click)="routerService.back()">
                {{ "back" | translate }}
            </button>
        </div>
        <div class="col-6 text-left">
            <button type="submit" class="btn btn-primary margin-bottom-10">{{ "save_changes" | translate }}</button>
        </div>

    </div>
</form>