import { TicketMultiRideViewDto } from "./ticket-multiride-view.dto";

export class PassDetailsViewDto {
  PassId: string = "";
  PassValidUntil?: Date;
  PassengerType: string = "";
  PassengerName: string = "";
  StartPoint?: string;
  EndPoint?: string;
  Zone?: string;
  MultiRideTickets: TicketMultiRideViewDto[];

  constructor() {
    this.MultiRideTickets = [];
  }
}
