<div class="container p-md-5">
    <div
        class="section mx-2 mx-md-5 my-5 text-left p-4 d-flex flex-column flex-md-row justify-content-center justify-content-md-between lh-lg">
        <div class="px-4">
            <div><strong class="me-1">{{ "passenger" | translate}}:</strong> {{passDetails.PassengerName}}</div>
            <div><strong class="me-1">{{ "legitimation_number" | translate}}:</strong> {{passDetails.PassId}}</div>
            <div><strong class="me-1">{{ "type" | translate}}:</strong> {{passDetails.PassengerType}}</div>
            <div *ngIf="!passDetails.Zone"><strong class="me-1">{{ "route_name" | translate}}:</strong>
                {{passDetails.StartPoint}} - {{passDetails.EndPoint}}</div>
            <div *ngIf="passDetails.Zone"><strong class="me-1">{{ "zone" | translate}}:</strong> {{passDetails.Zone}}
            </div>
        </div>
        <div class="px-4 mt-md-0 mt-5">
            <p class="font-20"><strong class="me-1">{{ "multiride_tickets" | translate}}</strong></p>
            <div>
                <div *ngIf="!passDetails.Zone"><strong class="me-1">{{ "route_name" | translate}}:</strong>
                    {{passDetails.StartPoint}}
                    - {{passDetails.EndPoint}}</div>
                <div *ngIf="passDetails.Zone"><strong class="me-1">{{ "zone" | translate}}:</strong>
                    {{passDetails.Zone}}</div>
                <div class="d-flex flex-row">
                    <strong class="text-nowrap me-1">{{ "period" | translate}}: </strong>
                    <select name="ticket-month" class="form-select" id="ticket-month" [(ngModel)]="selectedPeriodId">
                        <option *ngFor="let period of periods" value="{{period.Id}}">{{period.Name}}</option>
                    </select>
                </div>
                <div><strong class="me-1">{{ "price" | translate}}:</strong> {{price | number : '1.2-2'}} RSD</div>
            </div>
            <div class="text-center mt-4">
                <button class="btn btn-primary" id="buy-monthly" (click)="buy()">{{ "buy_monthly" | translate}}</button>
            </div>
        </div>
    </div>
    <!-- *ngIf="table?.count !== 0" -->
    <div class="mt-5 d-none d-md-flex align-middle">
        <table class="table">
            <thead>
                <tr class="align-middle">
                    <th>{{ "number" | translate }}</th>
                    <th>{{ "issued" | translate }}</th>
                    <th>{{ "serie" | translate }}</th>
                    <th>{{ "desc" | translate }}</th>
                    <th>{{ "serial_nr" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of table.data" class="align-middle font-14">
                    <td>{{row.Number}}</td>
                    <td>{{row.CreatedAt | date:'dd.MM.yyyy'}}</td>
                    <td>{{row.Series}}</td>
                    <td>{{row.PassengerDescription}}</td>
                    <td>{{row.SerialNumber}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-none d-md-flex justify-content-center">
        <ngb-pagination *ngIf="table.data.length > 0" [collectionSize]="table.count" [(page)]="table.page"
            (pageChange)="table.refreshData()" />
    </div>
    <div class="d-block d-md-none">
        <div *ngFor="let row of table.data" class="d-flex d-md-none justify-content-center" class="align-middle lh-2">
            <div class="section m-4">
                <div class="row m-2">
                    <div class="col-6 fw-bolder text-left">
                        <div>{{ "number" | translate }}</div>
                        <div>{{ "issued" | translate }}</div>
                        <div>{{ "serie" | translate }}</div>
                        <div>{{ "desc" | translate }}</div>
                        <div>{{ "serial_nr" | translate }}</div>
                    </div>
                    <div class="col-6 text-left">
                        <div>{{row.Number}}</div>
                        <div>{{row.CreatedAt | date:'dd.MM.yyyy'}}</div>
                        <div>{{row.Series}}</div>
                        <div>{{row.PassengerDescription}}</div>
                        <div>{{row.SerialNumber}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex d-md-none justify-content-center" *ngIf="table.count !== table.data.length">
            <button class="btn btn-primary mx-auto" (click)="table.loadMore()">
                Ucitaj jos
            </button>
        </div>
    </div>
</div>