import { keyframes } from "@angular/animations";
import { tick } from "@angular/core/testing";
import { ReservationViewDto } from "src/dtos/reservations/reservation-view.dto";
import { TicketResponseDto } from "src/dtos/ticket/ticket-response.dto";
import { DatedJourneyDto } from "../dtos/dated-journey/dated-journey.dto";
import { PaymentDto } from "../dtos/payment.dto";

export class TicketCreateDo extends DatedJourneyDto implements PaymentDto {
  PassengerTypeId!: number;
  PassengerPrivilegeId?: number; ///?
  PassengerPassId: string = ""; ///?
  Email: string = "";
  Quantity!: number;
  TotalPrice!: number;
  RoundtripDatedJourneyId!: number;
  IsOpenRoundtripJourney: boolean = false;

  OriginalEndPointId!: number;//ovo je za odabir povratnih polaza za promo stanice
  OriginalStartPointId!: number;//ovo je za odabir povratnih polaza za promo stanice

  //Loyalty
  LoyaltyAmount?: number;
  LoyaltyUserId?: number;

  ///NOT USED
  /*
  
  RouteName: string = "";
  PtOperatorId!: number;
  PtOperatorBussinesName: string = "";
  StrPassengerDepartureDate: string = "";
  StrPassengerDepartureTime: string = "";
  StrPassengerArrivalDate: string = "";
  StrPassengerArrivalTime: string = "";

  OriginalDate!: Date;
  OriginalStartPointName: string = "";
  OriginalEndPointName: string = "";
    MinTicketPrice!: number;

    
  IsPromo: boolean = false;
  */

  Reservations: ReservationViewDto[] = [];//ReservationViewDto

  OrderCode!: string;
  Amount!: string;
  ClientId!: string;
  Currency!: string;
  FailUrl!: string;
  HashAlgorithm!: string;
  Installment!: string;
  Lang!: string;
  OkUrl!: string;
  OrderId!: string;
  Rnd!: string;
  StoreType!: string;
  TransactionType!: string;
  Shopurl!: string;
  RemainingSecond!: string;
  Hash!: string;


  constructor(ticket: TicketCreateDo | undefined = undefined) {
    super();
    this.IsOpenRoundtripJourney = false;
    if (!ticket) {
      return;
    }

    if (ticket instanceof TicketCreateDo) {
      this.map(ticket);

      this.PassengerTypeId = ticket.PassengerTypeId
      this.PassengerPrivilegeId = ticket.PassengerPrivilegeId;
      this.PassengerPassId = ticket.PassengerPassId;
      this.Email = ticket.Email;
      this.Quantity = ticket.Quantity;
      this.TotalPrice = ticket.TotalPrice;
      this.RoundtripDatedJourneyId = ticket.RoundtripDatedJourneyId;
      this.IsOpenRoundtripJourney = ticket.IsOpenRoundtripJourney;

      this.OriginalEndPointId = ticket.OriginalEndPointId;
      this.OriginalStartPointId = ticket.OriginalStartPointId;

      this.LoyaltyAmount = ticket.LoyaltyAmount;
      this.LoyaltyUserId = ticket.LoyaltyUserId;

      this.DatedJourneyId = ticket.DatedJourneyId;
      this.PassengerDepartureTime = ticket.PassengerDepartureTime;
      this.PassengerArrivalTime = ticket.PassengerArrivalTime;

      this.StartPointId = ticket.StartPointId;
      this.EndPointId = ticket.EndPointId;
      this.StartPointName = ticket.StartPointName;
      this.EndPointName = ticket.EndPointName;

      this.IsConnectedJourney = ticket.IsConnectedJourney;
      this.IsPromo = ticket.IsPromo;
    }
  }

  map(paymentDetails: PaymentDto): void {
    this.OrderCode = paymentDetails.OrderCode;
    this.Amount = paymentDetails.Amount;
    this.ClientId = paymentDetails.ClientId
    this.Currency = paymentDetails.Currency;
    this.FailUrl = paymentDetails.FailUrl;
    this.HashAlgorithm = paymentDetails.HashAlgorithm;
    this.Installment = paymentDetails.Installment;
    this.Lang = paymentDetails.Lang;
    this.OkUrl = paymentDetails.OkUrl;
    this.OrderId = paymentDetails.OrderId;
    this.Rnd = paymentDetails.Rnd;
    this.StoreType = paymentDetails.StoreType;
    this.TransactionType = paymentDetails.TransactionType;
    this.Shopurl = paymentDetails.Shopurl;
    this.RemainingSecond = paymentDetails.RemainingSecond;
    this.Hash = paymentDetails.Hash;
  }

  public static mapTicketResponse(paymentDetails: TicketResponseDto, ticket: TicketCreateDo | undefined = undefined): TicketCreateDo {
    if (!ticket) {
      ticket = new TicketCreateDo();
    }

    ticket.OrderCode = paymentDetails.OrderCode;
    ticket.Amount = paymentDetails.Amount;
    ticket.ClientId = paymentDetails.ClientId
    ticket.Currency = paymentDetails.Currency;
    ticket.FailUrl = paymentDetails.FailUrl;
    ticket.HashAlgorithm = paymentDetails.HashAlgorithm;
    ticket.Installment = paymentDetails.Installment;
    ticket.Lang = paymentDetails.Lang;
    ticket.OkUrl = paymentDetails.OkUrl;
    ticket.OrderId = paymentDetails.OrderId;
    ticket.Rnd = paymentDetails.Rnd;
    ticket.StoreType = paymentDetails.StoreType;
    ticket.TransactionType = paymentDetails.TransactionType;
    ticket.Shopurl = paymentDetails.Shopurl;
    ticket.RemainingSecond = paymentDetails.RemainingSecond;
    ticket.Hash = paymentDetails.Hash;
    ticket.Reservations = paymentDetails.Reservations;

    return ticket;
  }
}

