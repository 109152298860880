import { Component, Injector, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TicketCreateDo } from 'src/dos/ticket-create.do';
import { UserViewDto } from 'src/dtos/users/user-view.dto';
import { Table } from 'src/libs/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AgencyTicketCreateDto } from 'src/dtos/agency/agency-ticket-create-dto';
import { AgancyTicketModalComponent } from 'src/components/agancy-ticket-modal/agancy-ticket-modal.component';
import { ModalDo } from 'src/dos/modal.do';
import { ModalComponent } from 'src/components/modal/modal.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/app.constants';
import { SharedDo } from 'src/dos/shared/shared.do';

@Component({
  selector: 'app-ticket-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule, ModalComponent, FormsModule, AgancyTicketModalComponent, NgbProgressbarModule],
  templateUrl: './ticket-preview.page.html',
  styleUrls: ['./ticket-preview.page.scss']
})
export class TicketPreviewPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isCheckedTermsAndConditions: boolean = false;

  ticket: TicketCreateDo = new TicketCreateDo();
  user!: UserViewDto | null;
  table = signal<Table<TicketCreateDo>>(new Table(1, 10));

  agencyNote: string = "";
  isClickedYes: boolean = false;
  isAgency: boolean = false;

  time: string = "10:00";
  timeProgress: number = 0;

  constructor(private injector: Injector, private modalService: NgbModal) {
    super(injector);
    this.sharedService.broadcast(this.constants.EV_RESERVATION_CREATED);
    this.user = this.storageService.getUserData();
    const storedTicket = this.storageService.getTicket();
    this.subscriptions.push(this.sharedService.on(this.constants.EV_REMAINIG_RESERVATION_TIME, this.timerTick.bind(this)));
    if (!storedTicket) {
      this.routerService.navigate('index');
    }
    else {
      this.ticket = storedTicket;
    }

    if (this.ticket.Reservations?.length > 0) {
      this.table().setData(this.ticket.Reservations);
    }

    if (this.authenticationService.isAuthenticated()) {
      this.user = this.storageService.getUserData();
      this.isAgency = this.user?.IsAgency ?? false;
    }
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  back() {
    this.routerService.navigate("reservation-create");
  }

  openAgencyModal() {
    this.isCheckedTermsAndConditions = false;
    const ref = this.modalService.open(AgancyTicketModalComponent, { scrollable: true });
    ref.componentInstance.setNote(this.agencyNote);
    ref.result.then((result) => {
      if (result == 'YES') {
        this.isClickedYes = true;
        this.agencyNote = ref.componentInstance.getNote();
        this.buyForAgency();
      }
    }, () => { });
  }

  buyForAgency() {
    var data = new AgencyTicketCreateDto();
    data.OrderCode = this.ticket.OrderCode;
    data.Note = this.agencyNote;
    data.loyaltyUserId = this.ticket.LoyaltyUserId;

    this.storageService.clearTicket();
    this.subscriptions.push(this.webapiTicketService.createTicketForAgency(data).subscribe((response) => {
      if (response == null || response == undefined || Object.keys(response).length === 0) {
        this.commonService.alertError("ticket_not_created");
        this.routerService.navigate("index");
        return;
      }
      this.storageService.setAgencyTickets(response);
      this.routerService.navigate("agency-ticket-preview");
    }));
  }

  open(content: string, title: string = "") {
    var body: ModalDo = {
      content: content,
      title: title
    };

    const ref = this.modalService.open(ModalComponent, { size: 'xl', scrollable: true });
    ref.componentInstance.setText(body);
  }

  timerTick(data: SharedDo) {
    this.time = data.Data;
    var expaird_time = this.storageService.getReservationDuration() ?? 0;
    this.timeProgress = 100 * (expaird_time / 600);
  }
}
