import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { SignUpDto } from 'src/dtos/sign-up/sign-up.dto';
import { UserChangePasswordDto } from 'src/dtos/users/user-change-password.dto';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  selector: 'change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  submitted = false;
  form = this.formBuilder.group({
    OldPassword: ['', Validators.required],
    NewPassword: ['', Validators.required]
  });

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.subscriptions.push(this.webapiIdentityService.changePassword(<UserChangePasswordDto>this.form.value).subscribe(res => {
      if (res.IsSuccess) {
        this.commonService.alert("password_changed_successfully");
        this.routerService.navigate("index");
      }
      else {
        this.commonService.alertError(res.Reason ?? res.ErrorMessage ?? "error");
        //this.model = new UserChangePasswordDto();
      }
    }));
  }
}
