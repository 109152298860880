// Angular
import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { share, filter } from 'rxjs/operators';
// Dtos
import { SharedDo } from 'src/dos/shared/shared.do';
//Services
import { LoggerService } from '../services/logger.service';

@Injectable()
export class SharedService {
    observable: Observable<SharedDo>;
    observer: Observer<SharedDo> | undefined;

    constructor(private loggerService: LoggerService) {
        this.observable = new Observable((observer: Observer<any>) => {
            this.observer = observer;
        }).pipe(share());
    }

    broadcast(name: string, data: any = null) {
        let sharedDo = new SharedDo();
        sharedDo.Name = name;
        sharedDo.Data = data;

        if (this.observer != null && this.observer != undefined) {
            this.observer.next(sharedDo);
        }
        else {
            this.loggerService.error("Observer object is NULL or undefined. Event name: " + name, "Shared Service");
        }

    }

    on(eventName: string, callback: any): Subscription {
        return this.observable.pipe(filter(event => event.Name === eventName)).subscribe(callback);
    }
}
