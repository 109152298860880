import { Component, computed, Injector, OnDestroy, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartureDescriptionComponent } from 'src/components/departure-description/departure-description.component';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription, TimeoutConfig } from 'rxjs';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TicketCreateDo } from 'src/dos/ticket-create.do';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto';
import { JourneyDo } from 'src/dos/journeys/journeys.do';
import { RoundtripDatedJourneySelectComponent } from 'src/components/roundtrip-dated-journey-select/roundtrip-dated-journey-select.component';
import { DatedJourneyDetailsViewDto } from 'src/dtos/dated-journey/dated-journey-details-view.dto';
import { LoyaltyUserDto } from 'src/dtos/loyalty-user.dto';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketCreateDto } from 'src/dtos/ticket/ticket-create.dto';
import { Constants } from 'src/app/app.constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatedJourneyPriceViewDto } from 'src/dtos/dated-journey/dated-journey-price-view.dto';
import { SharedDo } from 'src/dos/shared/shared.do';

@Component({
  selector: 'app-ticket-create',
  standalone: true,
  imports: [CommonModule, TranslateModule, RoundtripDatedJourneySelectComponent, ReactiveFormsModule, FormsModule, DepartureDescriptionComponent, NgbTooltipModule, NgbProgressbarModule],
  templateUrl: './ticket-create.page.html',
  styleUrls: ['./ticket-create.page.scss'],
  animations: [
    trigger('fade', [
      state('out', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),
      transition('* <=> *', [
        animate(150)
      ])
    ])
  ]
})
export class TicketCreatePage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  isAgency: boolean = this.storageService.isAgency();
  isAuthenticated: boolean = this.authenticationService.isAuthenticated();
  isStandardTicket: boolean = true;
  rideDescription: Signal<JourneyDo>;
  canCreateOpenReturnJourney: boolean = false;
  submitted = false;

  ticket: WritableSignal<TicketCreateDo>;
  price: number = 0;
  originalPrice: number = 0;
  IsRoundtrip: boolean = false;
  inProgress: boolean = false;

  journeyDetails: DatedJourneyDetailsViewDto = new DatedJourneyDetailsViewDto();
  //Loyalty
  loyaltyUser = signal<LoyaltyUserDto>(new LoyaltyUserDto());
  showLoyaltyCard: boolean = false;
  useLoyatyBonus: boolean = false;

  time: string = "10:00";
  timeProgress: number = 0;

  refreshPriceTime: number = Constants.PRICE_REFRESH_TIME;
  priceInterval: ReturnType<typeof setInterval> | null = null;

  form = this.formBuilder.group({
    DatedJourneyId: [<number | undefined>undefined, [Validators.required]],
    StartPointId: [<number>0, [Validators.required]],
    EndPointId: [<number>0, [Validators.required]],
    Quantity: [<number>1, [Validators.required, Validators.max(5)]],

    RoundtripDatedJourneyId: [<number | null>null],
    IsNotOpenRoundtripJourney: [<boolean>true],

    Email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
    Phone: [<string | null>null],

    LoyaltyAmount: [<number | undefined>undefined],
    LoyaltyUserId: [<number | undefined>undefined],

    PassengerTypeId: [<number | null>null, Validators.required],
    PassengerPrivilegeId: [<number | null>null],
    PassengerPassId: [<string>""]
  });

  constructor(private injector: Injector) {
    super(injector);
    this.subscriptions.push(this.sharedService.on(this.constants.EV_REMAINIG_RESERVATION_TIME, this.timerTick.bind(this)));
    const storedTicket = this.storageService.getTicket();
    if (storedTicket) {
      this.ticket = signal<TicketCreateDo>(storedTicket);
    }
    else {
      this.ticket = signal<TicketCreateDo>(new TicketCreateDo());
      this.routerService.navigate("index");
    }

    if (this.ticket().OriginalPrice == this.ticket().Price) {
      this.ticket().IsPromo = false;
    }
    this.timeProgress = 0;
    this.rideDescription = computed(() => { return new JourneyDo(<DatedJourneyDto>this.ticket()); });
    this.canCreateOpenReturnJourney = this.authenticationService.isAuthenticated() && !this.storageService.isAgency();
  }

  ngOnInit(): void {
    this.form.controls['Email'].setValue(this.ticket().Email);
    this.form.controls['RoundtripDatedJourneyId'].setValue(this.ticket().RoundtripDatedJourneyId);
    this.form.controls['IsNotOpenRoundtripJourney'].setValue(!this.ticket().IsOpenRoundtripJourney);
    this.form.controls['Quantity'].setValue(this.ticket().Quantity ?? 1);
    this.IsRoundtrip = this.ticket().IsOpenRoundtripJourney || (this.ticket().RoundtripDatedJourneyId != null);

    this.subscriptions.push(this.webapiJourneysService.getDetails(this.ticket().DatedJourneyId, this.ticket().StartPointId, this.ticket().EndPointId).subscribe((res) => {
      if (!res.IsValid) {
        this.commonService.alertError(res.Message);
        this.routerService.back();
        return;
      }

      if (res.Prices.length == 0) {
        this.commonService.alertError('prices_not_defined');
        this.routerService.back();
        return;
      }

      this.showLoyaltyCard = res.IsLoyaltyAvailable;
      this.journeyDetails = res;
      this.form.controls['DatedJourneyId'].setValue(this.ticket().DatedJourneyId);
      this.form.controls['PassengerTypeId'].setValue(res.Prices[0]?.PassengerTypeId);
      this.form.controls["StartPointId"].setValue(this.ticket().StartPointId);
      this.form.controls["EndPointId"].setValue(this.ticket().EndPointId);

      if (this.authenticationService.isAuthenticated()) {
        const email = this.storageService.getUserData()?.Email;
        if (email) {
          this.form.controls['Email'].setValue(email);
          this.form.controls['Email'].disable();
        }

        const phone = this.storageService.getUserData()?.Phone;
        if (phone) {
          this.form.controls['Phone'].setValue(phone);
        }

        const loyalty = this.storageService.getLoyaltyCardId();
        if (loyalty) {
          const loy = new LoyaltyUserDto();
          loy.LoyaltyCardId = loyalty;
          this.loyaltyUser.set(loy);
          this.searchLoyaltyCard();
        }

      }

      this.setPrice();
    }));

    if (!this.isAgency) {
      this.priceInterval = setInterval(() => {
        if (this.refreshPriceTime < 1) {
          this.refreshPriceTime = Constants.PRICE_REFRESH_TIME;
          this.refreshPrice();
        }
        else {
          this.refreshPriceTime--;
        }
      }, 1000)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    if (this.priceInterval) {
      clearInterval(this.priceInterval);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.IsRoundtrip && !this.form.controls["RoundtripDatedJourneyId"].value && this.form.controls["IsNotOpenRoundtripJourney"].value) {
      this.commonService.alertError("select_roundtrip_journey");
      return;
    }

    if (this.useLoyatyBonus && !this.validateLoyaltyAmount()) {
      this.commonService.alertError("useLoyatyBonus");
      return;
    }

    const data: TicketCreateDto = <TicketCreateDto>this.form.value;
    data.IsOpenRoundtripJourney = !this.form.controls["IsNotOpenRoundtripJourney"].value && this.IsRoundtrip && !this.form.controls["RoundtripDatedJourneyId"].value;
    data.OrderCode = this.ticket().OrderCode;

    data.OriginalStartPointId = data.StartPointId;
    data.OriginalEndPointId = data.EndPointId;


    this.subscriptions.push(this.webapiReservationService.createTicketReservation(data).subscribe((res) => {
      if (res.IsSuccess) {
        TicketCreateDo.mapTicketResponse(res, this.ticket());
        this.ticket().Quantity = data.Quantity;
        this.ticket().Email = data.Email;
        this.ticket().IsOpenRoundtripJourney = data.IsOpenRoundtripJourney;
        if (data.RoundtripDatedJourneyId) {
          this.ticket().RoundtripDatedJourneyId = data.RoundtripDatedJourneyId
        }

        if (this.loyaltyUser() != null) {
          this.ticket().LoyaltyAmount = this.form.controls["LoyaltyAmount"].value ?? undefined;;
          this.ticket().LoyaltyUserId = this.loyaltyUser().LoyaltyUserId;
        }
        this.storageService.setTicket(this.ticket(), res.DurationInSeconds);
        this.sharedService.broadcast(Constants.EV_RESERVATION_CREATED);
        this.routerService.navigate("ticket-preview");
      }
      else {
        this.commonService.alertError(res.Reason)
      }
    }));

  }

  getLoyaltyMaxAmount() {
    if (!this.loyaltyUser()) {
      return 0;
    }

    var max = (this.ticket().Price ?? 0) * this.loyaltyUser().MaxAmountOfUseInPercentages / 100;
    if (this.loyaltyUser().LoyaltyBalance < max) {
      return this.loyaltyUser().LoyaltyBalance;
    }

    return max;
  }

  validateLoyaltyAmount() {
    var loyaltyAmount = this.form.controls['LoyaltyAmount'].value;
    if (!loyaltyAmount) {
      this.commonService.alertError("enter_loyalty_amount");
      return false;
    }
    else if (loyaltyAmount < this.loyaltyUser().MinAmountOfUse) {
      this.commonService.alertError("loyalty_amount_is_less_then_minimum");
      loyaltyAmount = this.loyaltyUser().MinAmountOfUse;
      return false;
    }
    else if (loyaltyAmount > this.getLoyaltyMaxAmount()) {
      this.commonService.alertError("loyalty_amount_is_greater_then_maximum");
      //this.ticket().LoyaltyAmount = this.journeyDetails.LoyaltyMinAmount;
      loyaltyAmount = this.getLoyaltyMaxAmount();
      return false;
    }
    this.form.controls['LoyaltyAmount'].setValue(Math.round((loyaltyAmount ?? 0) * 100) / 100);

    return true;
  }

  searchLoyaltyCard() {
    const loyaltyCardId = this.loyaltyUser().LoyaltyCardId;
    if (loyaltyCardId == "") {
      this.commonService.alertError("enter_loyalty_card_number");
      return;
    }

    this.webapiCommonService.getLoyaltyUser(loyaltyCardId).subscribe(res => {
      if (!res) {
        this.commonService.alertError("loyalty_user_not_found");
        this.cancelLoyaltyCard();
        return;
      }

      this.loyaltyUser.set(res);
      this.form.controls['LoyaltyUserId'].setValue(this.loyaltyUser().LoyaltyUserId)
      //this.form.controls['LoyaltyAmount'].setValue(this.loyaltyUser().MinAmountOfUse);
    });
  }

  cancelLoyaltyCard() {
    this.loyaltyUser.set(new LoyaltyUserDto());
    this.form.controls['LoyaltyUserId'].setValue(null);
    this.form.controls['LoyaltyAmount'].setValue(null);
    this.ticket().LoyaltyUserId = undefined;
  }

  quantityChanged() {
    this.refreshPrice();
  }

  categoryChanged() {
    var category = this.journeyDetails.Prices.find(x => x.PassengerTypeId == this.form.controls["PassengerTypeId"].value);
    if (category == null || category == undefined) {
      return;
    }
    this.isStandardTicket = category?.PassengerTypeId == 1;
    if ((category?.Price ?? 0) < this.journeyDetails.MinTicketPrice) {
      setTimeout(() => {
        this.form.controls["PassengerTypeId"].setValue(1);
        this.form.controls["PassengerPrivilegeId"].setValue(null);
        this.form.controls["PassengerPassId"].setValue("");
        this.setPrice();
      }, 200);

      this.commonService.alertWarning("price_is_less_then_min");
      return;
    }

    this.setPrice();

    if (category?.PassengerTypeId == 1) {
      this.form.controls["PassengerPrivilegeId"].setValue(null);
      this.form.controls["PassengerPassId"].setValue("");
    }
  }

  refreshPrice() {
    this.inProgress = true;
    this.webapiJourneysService.getPrice(this.ticket().DatedJourneyId, this.ticket().StartPointId, this.ticket().EndPointId, this.form.controls['Quantity'].value ?? 1).subscribe((res) => {
      if (res) {
        var prices: DatedJourneyPriceViewDto | undefined = this.journeyDetails.Prices.find(x => x.PassengerTypeId == this.form.controls['PassengerTypeId'].value);
        if (!prices) {
          prices = new DatedJourneyPriceViewDto();
          prices.PassengerTypeId = this.form.controls['PassengerTypeId'].value ?? 1;
          this.journeyDetails.Prices.push(prices);
        }

        this.inProgress = false;
        prices.Price = res.Price;
        prices.OriginalPrice = res.OriginalPrice;
        prices.RoundtripPrice = res.RoundtripPrice;
        prices.OriginalRoundtripPrice = res.OriginalRoundtripPrice;
        this.setPrice();
      }
    });
  }
  setPrice() {
    const category = this.journeyDetails.Prices.find(x => x.PassengerTypeId == this.form.controls['PassengerTypeId'].value);
    if (!category) {
      return;
    }

    if (this.IsRoundtrip) {
      this.price = (category.RoundtripPrice ?? category.Price ?? 0) * (this.form.controls['Quantity'].value ?? 1);
      this.originalPrice = (category.OriginalRoundtripPrice ?? category.OriginalPrice ?? 0) * (this.form.controls['Quantity'].value ?? 1);
    }
    else {

      this.price = (category.Price ?? 0) * (this.form.controls['Quantity'].value ?? 1);
      this.originalPrice = (category.OriginalPrice ?? 0) * (this.form.controls['Quantity'].value ?? 1);
    }
    //this.price = (this.IsRoundtrip != undefined || this.IsRoundtrip ? category.RoundtripPrice : category.Price) * (this.form.controls['Quantity'].value ?? 1);
    //this.netoPrice = (this.IsRoundtrip ?  category.NetoRoundtripPrice: category.NetoPrice ) *  (this.form.controls['Quantity'].value ?? 1);
  }

  setRoundtripDatedJourneyId(event: DatedJourneyDto) {
    this.form.controls['RoundtripDatedJourneyId'].setValue(event.DatedJourneyId);
  }

  back() {
    this.routerService.navigate('journeys/' + this.ticket().StartPointId + '/' + this.ticket().EndPointId + '/' + this.commonService.formatDate(this.ticket().PassengerDepartureTime, false, "-"))
  }

  timerTick(data: SharedDo) {
    this.time = data.Data;
    var expaird_time = this.storageService.getReservationDuration() ?? 0;
    this.timeProgress = 100 * (expaird_time / 600);
  }
}
