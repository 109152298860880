import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseImports } from 'src/libs/base-imports';
import { finalize, Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { CommonService } from '../common.service';

@Injectable()
export class LoaderInterceptorService
  implements HttpInterceptor {
  includeContentType = false;

  constructor(private commonService: CommonService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const showSpinner = req.headers.has(Constants.SHOW_LOADER);
    if (showSpinner) {
      this.commonService.showLoader();
    }
    return next.handle(req).pipe(
      finalize(() => {
        if (showSpinner) {
          req.headers.delete(Constants.SHOW_LOADER);
          this.commonService.hideLoader();
        }
      })
    );
  }
}
