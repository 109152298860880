import { Component, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { MultirideTicketDo } from 'src/dos/multiride/multiride-ticket.do';
import { NgbModal, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDo } from 'src/dos/modal.do';
import { ModalComponent } from 'src/components/modal/modal.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-multiride-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModule, FormsModule, NgbProgressbarModule],
  templateUrl: './multiride-preview.page.html',
  styleUrls: ['./multiride-preview.page.scss']
})
export class MultiridePreviewPage extends BaseImports {
  ticket: MultirideTicketDo = new MultirideTicketDo();
  isCheckedTermsAndConditions: boolean;

  constructor(private injector: Injector, private modalService: NgbModal) {
    super(injector);
    this.isCheckedTermsAndConditions = false;

    var ticket = this.storageService.getMultirideTicket();
    if (!ticket) {
      this.routerService.navigate("index");
      return;
    }

    this.ticket = ticket;
  }

  ngOnInit() {

  }

  back() {
    this.routerService.navigate("multiride-create/" + this.ticket.PassId);
  }

  openModal(content: string) {
    var body: ModalDo = {
      content: content,
      title: ""
    };

    const ref = this.modalService.open(ModalComponent, { size: 'md', scrollable: true });
    ref.componentInstance.setText(body);
  }
}
