<div class="title">
    <h1>{{ "change_password" | translate }}</h1>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="text-center margin-top-30">
    <div class="row justify-content-center">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "old_pass_change_password" | translate}}</label>
            <input type="password" formControlName="OldPassword" class="form-control" />
            <span class="error-messages" *ngIf="submitted && f(form, 'OldPassword')">{{ "required_field" | translate
                }}</span>
        </div>
    </div>
    <div class="row justify-content-center margin-top-10">
        <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12">
            <label>{{ "new_pass_change_password" | translate}}</label>
            <input type="password" formControlName="NewPassword" class="form-control" />
            <span class="error-messages" *ngIf="submitted && f(form, 'NewPassword')">{{ "required_field" | translate }}</span>
        </div>
    </div>
    <div class="form-group row margin-top-30">
        <div class="row margin-top-30 justify-content-center">
            <div class="col-6 text-right">
                <button type="button" class="btn btn-outline-primary margin-bottom-10" (click)="routerService.back()">
                    {{ "back" | translate }}
                </button>
            </div>
            <div class="col-6 text-left">
                <button type="submit" class="btn btn-primary margin-bottom-10">{{ "change_password" | translate }}</button>
            </div>
        </div>
    </div>
</form>