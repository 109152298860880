import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";

export class Table<T extends object> {
  page: number;
  pageSize: number;
  data: T[];
  headers: string[] = []
  count: number;
  _allData: T[];
  constructor(_page: number, _pageSize: number, config?: NgbPaginationConfig) {
    this.page = _page;
    this.pageSize = _pageSize;
    this.data = [];
    this.count = 0;
    this._allData = [];

    if (config) {
      config.size = 'sm';
      config.boundaryLinks = true;
      config.ellipses = false;
      config.rotate = true;
      config.pageSize = _pageSize;
      config.maxSize = 3;
    }
  }



  setData(_data: any[]) {
    this._allData = _data;
    this.count = _data.length;
    if (_data.length > 0) {
      this.headers = Object.keys(_data[0]);
    }
    this.refreshData();
  }

  refreshData() {
    this.data = this._allData.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  loadMore() {
    if (this.data.length == this._allData.length) {
      return;
    }
    if (this._allData.length / this.pageSize > this.page) {
      this.page++;
    }
    this.data = this._allData.slice(0, (this.page - 1) * this.pageSize + this.pageSize);
  }
}
