<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/#']"><img class="navbar-logo" src="./assets/img/white_logo_350.png"
                width="250" alt="logo"></a>
        <button class="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation" (click)="toggleModal(content)">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="d-none d-lg-block justify-content-end">
            <ng-container *ngTemplateOutlet="navItemsContent"></ng-container>
        </div>
    </div>
</nav>

<ng-template #content let-modal>
    <div class="modal-header">
        <nav class="navbar navbar-expand-lg w-100 px-2">
            <div class="container-fluid">
                <a class="navbar-brand" [routerLink]="['/#']"><img class="navbar-logo"
                        src="./assets/img/white_logo_350.png" width="250" alt="logo"></a>
                <button type="button" class="btn-close " #toggleButton aria-label="Close"
                    (click)="modal.dismiss('Cross click')"> <i class="fa-solid fa-xmark"></i></button>
            </div>
        </nav>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="navItemsContent"></ng-container>
    </div>
</ng-template>

<ng-template #navItemsContent>
    <ul class="navbar-nav header-nav-list">
        <li class="nav-item">
            <a class="nav-link text-uppercase active" href="http://nis-ekspres.rs">{{ 'site' | translate }} <span
                    class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-uppercase cursor-pointer" (click)="open('customer_service_popup')">
                {{"contact" | translate}}</a>
        </li>
        <li class="nav-item cursor-pointer">
            <a class="nav-link text-uppercase" (click)="open('informations_and_help_popup')">{{ 'help' | translate
                }}</a>
        </li>
        <li class="nav-item" *ngIf="!authenticationService.isAuthenticated()">
            <a class="nav-link text-uppercase" [routerLink]="['/sign-up']">{{ 'sign_up' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="storageService.isAgency()">
            <a class="nav-link text-uppercase" [routerLink]="['/agency-report']">{{ 'report' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="authenticationService.isAuthenticated()">
            <a class="nav-link text-uppercase" [routerLink]="['/multiride-passenger-list']">{{ 'multiride_tickets' |
                translate }}</a>
        </li>
        <li class="nav-item" *ngIf="authenticationService.isAuthenticated() && !storageService.isAgency()">
            <a class="nav-link text-uppercase" [routerLink]="['/user-tickets-preview']">{{ 'my_tickets' | translate
                }}</a>
        </li>
        <li class="nav-item" *ngIf="authenticationService.isAuthenticated()">
            <a class="nav-link text-uppercase" [routerLink]="['/profile']"><b>{{storageService.getUsername()}}</b></a>
        </li>
        <li class="nav-item cursor-pointer" *ngIf="authenticationService.isAuthenticated()">
            <a class="nav-link text-uppercase" (click)="logout()">{{ 'logout' | translate }}</a>
        </li>
        <li #dropdown ngbDropdown class="nav-item d-none d-lg-block" *ngIf="!authenticationService.isAuthenticated()">
            <a class="nav-link" tabindex="0" ngbDropdownToggle id="signInDropdown" role="button">

                <img src="../../assets/icons/account.svg" width="24" class="margin-right-5">
            </a>
            <div ngbDropdownMenu aria-labelledby="signInDropdown"
                class="dropdown-menu dropdown-menu-end transparent-section">
                <sign-in-component></sign-in-component>
            </div>
        </li>
        <li class="nav-item mx-auto ps-2 ps-lg-0">
            <span class="nav-item d-flex flex-row align-items-center">
                <a class="nav-link cursor-pointer me-1 me-lg-0" (click)="setLanguage('sr')"
                    [ngClass]="{ 'fw-bold': ('sr' === localizationService.getLanguage().LanguageCode)}">SR</a>
                <span class="align-middle">|</span>
                <a class="nav-link cursor-pointer ms-1 ms-lg-0" (click)="setLanguage('en')"
                    [ngClass]="{ 'fw-bold': ('en' === localizationService.getLanguage().LanguageCode)}">EN</a>
            </span>
        </li>
        <li class="nav-item d-lg-none text-center mt-4" *ngIf="!authenticationService.isAuthenticated()">
            <sign-in-component></sign-in-component>
        </li>
    </ul>
</ng-template>