import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Table } from 'src/libs/table';
import { NgbPaginationConfig, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TicketVerifyViewDto } from 'src/dtos/ticket/ticket-verify-view.dto';

@Component({
  selector: 'app-user-tickets-preview',
  standalone: true,
  imports: [CommonModule, NgbPaginationModule, TranslateModule],
  templateUrl: './user-tickets-preview.page.html',
  styleUrls: ['./user-tickets-preview.page.scss'],
  providers: [
    NgbPaginationConfig
  ]
})
export class UserTicketsPreviewPage extends BaseImports implements OnInit, OnDestroy {
  tickets: Table<TicketVerifyViewDto>;
  DatedJourneyId!: number;
  subscriptions: Subscription[] = [];

  constructor(private injector: Injector, config: NgbPaginationConfig) {
    super(injector);
    this.tickets = new Table<TicketVerifyViewDto>(1, 10, config)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(this.webapiTicketService.getOpenJourneyTickets().subscribe(data => {
      this.tickets.setData(data);
    }));
  }

  next(ticket: TicketVerifyViewDto) {
    this.storageService.setOpenJourneyTickets(ticket);
    this.routerService.navigate('open-journey-ticket-create');
  }
}
