import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { WebApiBaseService } from './webapi-base.service';
import { SignUpDto } from 'src/dtos/sign-up/sign-up.dto';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';
import { ForgotPasswordDto } from 'src/dtos/forgot-password/forgot-password.dto';
import { UserViewDto } from 'src/dtos/users/user-view.dto';
import { UserUpdateDto } from 'src/dtos/users/user-update.dto';
import { UserChangePasswordDto } from 'src/dtos/users/user-change-password.dto';

@Injectable()
export class WebapiIdentityService extends WebApiBaseService {

    baseUrl = Constants.BASE_URL + "Identity";
    constructor(private injector: Injector) {
        super(injector);
    }

    registerUser(data: SignUpDto): Observable<ResponseTemplateDto<boolean>> {
        return this.http.post<ResponseTemplateDto<boolean>>(this.baseUrl, data);
    }

    forgotPassword(data: ForgotPasswordDto): Observable<ResponseTemplateDto<boolean>> {
        return this.http.post<ResponseTemplateDto<boolean>>(this.baseUrl + "/ForgotPassword", data, this.getHttpParams());
    }

    getProfile(): Observable<UserViewDto> {
        return this.http.get<UserViewDto>(this.baseUrl, this.getHttpParams());
    }

    updateProfile(data: UserUpdateDto): Observable<ResponseTemplateDto<boolean>> {
        return this.http.put<ResponseTemplateDto<boolean>>(this.baseUrl, data, this.getHttpParams());
    }

    changePassword(data: UserChangePasswordDto): Observable<ResponseTemplateDto<boolean>> {
        return this.http.post<ResponseTemplateDto<boolean>>(this.baseUrl + "/ChangePassword", data, this.getHttpParams());
    }
}
