import { Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
//services
import { RouterService } from 'src/services/router.service';
import { SharedService } from 'src/services/shared.service';
import { AuthenticationService } from "src/services/authentication.service";
import { CommonService } from 'src/services/common.service';
import { WebApiCommonService } from 'src/services/webapi-common.service';
import { WebapiIdentityService } from 'src/services/webapi-identity.service';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from 'src/services/logger.service';
import { StorageService } from 'src/services/storage.service';
import { WebapiJourneysService } from 'src/services/webapi-journeys.service';
import { LocalizationService } from 'src/services/localization.service';
import { Constants } from 'src/app/app.constants';
import { WebapiReservationService } from 'src/services/webapi-reservation.service';
import { WebapiTicketService } from 'src/services/webapi-ticket.service';
import { WebapiMultirideService } from 'src/services/webapi-multiride.service';
import { WebapiSessionService } from 'src/services/webapi-session.service';

export class BaseImports {
  formBuilder: FormBuilder;
  route: ActivatedRoute;
  routerService: RouterService;
  sharedService: SharedService;
  authenticationService: AuthenticationService;
  commonService: CommonService;
  loggerService: LoggerService;
  storageService: StorageService;
  translateService: TranslateService;
  localizationService: LocalizationService;
  webapiCommonService: WebApiCommonService;
  webapiIdentityService: WebapiIdentityService;
  webapiJourneysService: WebapiJourneysService;
  webapiReservationService: WebapiReservationService;
  webapiTicketService: WebapiTicketService;
  webapiMultirideService: WebapiMultirideService;
  webapiSessionService: WebapiSessionService;
  constants = Constants;
  http: HttpClient;

  constructor(injector: Injector) {
    this.formBuilder = injector.get(FormBuilder);
    this.routerService = injector.get(RouterService);
    this.sharedService = injector.get(SharedService);
    this.storageService = injector.get(StorageService);
    this.authenticationService = injector.get(AuthenticationService);
    this.commonService = injector.get(CommonService);
    this.loggerService = injector.get(LoggerService);
    this.route = injector.get(ActivatedRoute);
    this.translateService = injector.get(TranslateService);
    this.localizationService = injector.get(LocalizationService);
    this.webapiCommonService = injector.get(WebApiCommonService);
    this.webapiIdentityService = injector.get(WebapiIdentityService);
    this.webapiJourneysService = injector.get(WebapiJourneysService);
    this.webapiReservationService = injector.get(WebapiReservationService);
    this.webapiTicketService = injector.get(WebapiTicketService);
    this.webapiMultirideService = injector.get(WebapiMultirideService);
    this.webapiSessionService = injector.get(WebapiSessionService);
    this.http = injector.get(HttpClient);
  }

  f(form: FormGroup<any>, key: string, errorType: string = 'required') {
    if (form &&
      form.controls[key] &&
      form.controls[key].hasError(errorType) //&&
      //form.controls[key].touched && 
      //form.controls[key].dirty
    ) {
      return true;
    }
    return false;
  }
}
