<div class="title my-2 sign-in_section">{{ "sign_in_user" | translate}}</div>
<form [formGroup]="form" (ngSubmit)="signIn()" class="col">
    <div class="form-group mb-3 px-0  text-center">
        <label>{{ "username" | translate}}</label>
        <input type="text" class="form-control input-group white-input" formControlName="Email">
        <span class="error-messages" *ngIf="submitted && f(form, 'Email')">{{ "required_field" | translate
            }}</span>
    </div>

    <div class="form-group px-0 text-center">
        <label>{{ "password" | translate}}</label>
        <input type="password" class="form-control input-group white-input" formControlName="Password">
        <span class="error-messages" *ngIf="submitted && f(form, 'Password')">{{ "required_field" | translate
            }}</span>
    </div>
    <div class="sign-in_link mt-1">
        <a [routerLink]="['/forgot-password']"> {{ "forgot_your_password" | translate }}?
        </a>
    </div>

    <div class="my-4 d-flex">
        <button type="submit" class="btn btn-primary mx-auto me-md-0"> {{ "login" | translate }}</button>
    </div>

    <div class="mb-0">
        <div class="sign-in_link text-shadow first-letter-uppercase">
            {{'no_account' | translate}}?
            <a [routerLink]="['/sign-up']" class="first-letter-uppercase ms-1">
                {{ "sign_up" | translate }}</a>
        </div>
    </div>
</form>