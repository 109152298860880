<div class="title">
    <h1>Zabravili ste šifru?</h1>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="text-center mt-5 pt-2">
    <div class="row justify-content-center">
        <div class="form-group col-lg-3 col-lg-4 col-md-5 col-sm-6 col-12">
            <label>{{ "Email" | translate}}</label>
            <input type="text" formControlName="Email" class="form-control"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
            <span *ngIf="submitted && f(form, 'Email')">{{ "required_field" | translate }}</span>
        </div>
    </div>
    <div class="text-center mx-5 my-3">
        {{ "params_will_be_sent_to_email" | translate }}
    </div>
    <div class="form-group row mt-5">

        <div class="col text-center mt-4">
            <button type="button" class="btn btn-outline-primary margin-right-30" (click)="routerService.back()">{{ "back" | translate
                }}</button>
            <button type="submit" class="btn btn-primary">{{ "send" | translate }}</button>
        </div>
    </div>
</form>