import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { PaymentDto } from 'src/dtos/payment.dto';
import { PassDetailsViewDto } from 'src/dtos/tickets-multiride/pass-details-view.dto';
import { TicketMultiRideCreateDto } from 'src/dtos/tickets-multiride/ticket-multiride-create.dto';
import { TicketMultiRidePeriodViewDto } from 'src/dtos/tickets-multiride/ticket-multiride-period-view.dto';
import { TicketMultiRidePriceFilterDto } from 'src/dtos/tickets-multiride/ticket-multiride-price-filter.dto';
import { TicketMultiRidePriceViewDto } from 'src/dtos/tickets-multiride/ticket-multiride-price-view.dto';
import { WebApiBaseService } from './webapi-base.service';

@Injectable()
export class WebapiMultirideService extends WebApiBaseService {

  baseUrl = Constants.BASE_URL + "Multiride";
  constructor(private injector: Injector) {
    super(injector);
  }

  create(ticket: TicketMultiRideCreateDto): Observable<PaymentDto> {
    return this.http.post<PaymentDto>(this.baseUrl + "/Create", ticket, this.getHttpParams());
  }

  getPassDetails(passId: string): Observable<PassDetailsViewDto> {
    return this.http.get<PassDetailsViewDto>(this.baseUrl + "/Pass/" + passId, this.getHttpParams());
  }

  getPassPrice(filter: TicketMultiRidePriceFilterDto): Observable<TicketMultiRidePriceViewDto> {
    return this.http.post<TicketMultiRidePriceViewDto>(this.baseUrl + "/Pass/Price", filter, this.getHttpParams());
  }

  getTicketMultiRidePeriods(): Observable<TicketMultiRidePeriodViewDto[]> {
    return this.http.get<TicketMultiRidePeriodViewDto[]>(this.baseUrl + '/Periods', this.getHttpParams());
  }
}
