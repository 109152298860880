export class DatedJourneyDto {
    DatedJourneyId!: number;
    PassengerDepartureTime!: Date;
    PassengerArrivalTime!: Date;

    StartPointId!: number;
    EndPointId!: number;
    StartPointName: string = "";
    EndPointName: string = "";

    IsConnectedJourney: boolean = false;


    AdditionDiscount?: number = 0;
    Price?: number = 0;
    OriginalPrice?: number = 0;
    SeatsAvailable?: number = 0;
    IsPromo: boolean = false;
    ///NOT USED
    /*
    StrPassengerArrivalDate: string = "";
    StrPassengerArrivalTime: string = "";
    StrPassengerDepartureDate: string = ""; 
    StrPassengerDepartureTime: string = "";
     
    PtOperatorId!: number;
    PtOperatorBussinesName: string = "";
    RouteName: string = "";
    
    PtServiceTypeName: string = "";
    PlatformNumber?: number;
    SeatsAvailable?: number;
    MinTicketPrice!: number;
    
     
    OriginalEndPointId!: number;//ovo je za odabir povratnih polaza za promo stanice
    OriginalStartPointId!: number;//ovo je za odabir povratnih polaza za promo stanice
    IsPromo: boolean = true;
    
    IsAvailableTicketSale: boolean = true;
    ReasonForUnavailableTicketSale: string = "";
     
    
    */

    constructor() {
        this.AdditionDiscount = 0;
    }
}