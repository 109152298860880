import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { WebApiBaseService } from './webapi-base.service';
import { TicketCreateDto } from 'src/dtos/ticket/ticket-create.dto';
import { TicketResponseDto } from 'src/dtos/ticket/ticket-response.dto';

@Injectable()
export class WebapiReservationService extends WebApiBaseService {

    baseUrl = Constants.BASE_URL + "Reservations";
    constructor(private injector: Injector) {
        super(injector);
    }

    createTicketReservation(reservation: TicketCreateDto): Observable<TicketResponseDto> {
        return this.http.post<TicketResponseDto>(this.baseUrl + "/Create", reservation, this.getHttpParams(true));
    }
}
