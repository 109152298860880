<div class="modal-header">
    <h4 class="modal-title">{{modalData().title}}</h4>
    <button type="button" class="btn-close " aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <i
            class="fa-solid fa-xmark"></i></button>
</div>
<div class="modal-body">
    <div class="" [innerHtml]="modalData().content | translate"></div>
</div>
<div class="modal-footer d-flex justify-content-center" *ngIf="isConfirm()">
    <button type="button" class="btn btn-outline-primary me-2" (click)="activeModal.close(false)">Nazad</button>
    <button type="button" class="btn btn-primary ms-2" (click)="activeModal.close(true)">Potvrdi</button>
</div>