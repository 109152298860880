<section id="agency-ticket-preview-section" style="min-height: calc(100vh - 330px);">
    <div class="container ">
        <div *ngFor="let report of reports" class="my-5">
            <div class="row d-flex justify-content-around">
                <!--<div class="col-xs-1 col-sm-3"></div>-->
                <div class="col-11 col-xs-10 col-md-9 col-lg-6 px-0">
                    <div class="agency-ticket-preview_ticket lh-sm">
                        <div class="row d-md-none lh-base col-xs-10 col-md-5">
                            <div class="text-center font-20 fw-bold">{{report.Ticket.RouteName}}</div>
                        </div>
                        <div class="row d-md-none lh-base justify-content-around font-20 fw-bold mb-2">
                            <div class="col"> {{report.Ticket.Date}} {{report.Ticket.Time}}</div>
                            <div class="col text-right"> {{ "direction" | translate}} 1</div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "ticket_number" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.TicketNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right d-none d-md-block">
                                <div class="row"><b>{{ "direction" | translate}} 1</b></div>
                            </div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-4">{{report.Ticket.Date}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme:</b></div>
                                    <div class="col-6 text-right">{{report.Ticket.Time}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row d-none d-md-flex mt-3 px-3 px-md-0">
                            <div class="col-3"><b>{{ "route_name" | translate}}:</b></div>
                            <div class="col">{{report.Ticket.RouteName}}</div>
                        </div>
                        <div class="row d-none d-md-flex px-3 px-md-0">
                            <div class="col-3"><b>{{ "pt_operator_business_name_agency_report" | translate}}:</b></div>
                            <div class="col">{{report.Ticket.PtOperatorBusinessName}}</div>
                        </div>
                        <div class="row px-3 px-md-0 mb-3">
                            <div class="col-md-6 mt-3">
                                <div class="row d-md-none">
                                    <div class="col-6 pe-0"><b>{{ "pt_operator_business_name_agency_report" |
                                            translate}}:</b>
                                    </div>
                                    <div class="col-6">{{report.Ticket.PtOperatorBusinessName}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.PassengerDepartureDate}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme polaska:</b></div>
                                    <div class="col-6">{{report.Ticket.PassengerDepartureTime}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "passcode" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.AssignId}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "platform" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.PlatformNumber}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "seat" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.SeatNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.Price}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.Commission}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price_pr" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.TransportPrice}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "st_service" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.TicketPTTaxesAmount}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "loyalty_discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.LoyaltyUsedAmount}}</div>
                                </div>
                                <div class="row d-none d-md-flex">
                                    <div class="col-8 pe-0"><b>{{ "total" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.TotalPrice}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-md-none lh-base font-20 fw-bold mb-2">
                            <div class="col text-right"> {{ "total" | translate}}:
                                {{report.Ticket.TotalPrice}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-xs-10 col-md-9 col-lg-6 px-0"
                    *ngIf="report.Ticket?.ConnectedTicketNumber != null || report.RoundtripTicket?.ConnectedTicketNumber != null">
                    <div *ngIf="report.Ticket.ConnectedTicketNumber != null "
                        class="agency-ticket-preview_ticket lh-sm">
                        <div class="row d-md-none lh-base col-xs-10 col-md-5">
                            <div class="text-center font-20 fw-bold">{{report.Ticket.RouteName}}</div>
                        </div>
                        <div class="row d-md-none lh-base justify-content-around font-20 fw-bold mb-2">
                            <div class="col"> {{report.Ticket.Date}} {{report.Ticket.Time}}</div>
                            <div class="col text-right"> {{ "direction" | translate}} 1</div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "ticket_number" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right d-none d-md-block">
                                <div class="row"><b>{{ "direction" | translate}} 1</b></div>
                            </div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.Date}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme:</b></div>
                                    <div class="col-6 text-right">{{report.Ticket.Time}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-none d-md-flex mt-3 px-3 px-md-0">
                            <div class="col-3"><b>{{ "route_name" | translate}}:</b></div>
                            <div class="col">{{report.Ticket.ConnectedTicketRouteName}}</div>
                        </div>
                        <div class="row d-none d-md-flex px-3 px-md-0">
                            <div class="col-3"><b>{{ "pt_operator_business_name_agency_report" | translate}}:</b></div>
                            <div class="col">{{report.Ticket.ConnectedTicketPtOperatorBusinessName}}</div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6 mt-3">
                                <div class="row d-md-none">
                                    <div class="col-6 pe-0"><b>{{ "pt_operator_business_name_agency_report" |
                                            translate}}:</b>
                                    </div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketPtOperatorBusinessName}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketPassengerDepartureDate}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme polaska:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketPassengerDepartureTime}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "passcode" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketAssignId}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "platform" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketPlatformNumber}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "seat" | translate}}:</b></div>
                                    <div class="col-6">{{report.Ticket.ConnectedTicketSeatNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3 mb-3">
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.ConnectedTicketPrice}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.ConnectedTicketCommission}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price_pr" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.ConnectedTicketTransportPrice}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "st_service" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.ConnectedTicketPTTaxesAmount}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "loyalty_discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.Ticket.ConnectedTicketLoyaltyUsedAmount}}
                                    </div>
                                </div>
                                <div class="row d-none d-md-flex">
                                    <div class="col-8 pe-0"><b>{{ "total" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.Ticket.ConnectedTicketTotalPrice}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-md-none lh-base font-20 fw-bold mb-2">
                            <div class="col text-right"> {{ "total" | translate}}:
                                {{report.Ticket.ConnectedTicketTotalPrice}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-around" *ngIf="report.RoundtripTicket">
                <div class="col-11 col-xs-10 col-md-9 col-lg-6 px-0">
                    <div class="agency-ticket-preview_ticket lh-sm">
                        <div class="row d-md-none lh-base col-xs-10 col-md-5">
                            <div class="text-center font-20 fw-bold">{{report.RoundtripTicket.RouteName}}</div>
                        </div>
                        <div class="row d-md-none lh-base justify-content-around font-20 fw-bold mb-2">
                            <div class="col"> {{report.RoundtripTicket.Date}} {{report.RoundtripTicket.Time}}</div>
                            <div class="col text-right"> {{ "direction" | translate}} 2</div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "ticket_number" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.TicketNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right d-none d-md-block">
                                <div class="row"><b>{{ "direction" | translate}} 2</b></div>
                            </div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-4">{{report.RoundtripTicket.Date}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme:</b></div>
                                    <div class="col-6 text-right">{{report.RoundtripTicket.Time}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row d-none d-md-flex mt-3 px-3 px-md-0">
                            <div class="col-3"><b>{{ "route_name" | translate}}:</b></div>
                            <div class="col">{{report.RoundtripTicket.RouteName}}</div>
                        </div>
                        <div class="row d-none d-md-flex px-3 px-md-0">
                            <div class="col-3"><b>{{ "pt_operator_business_name_agency_report" | translate}}:</b></div>
                            <div class="col">{{report.RoundtripTicket.PtOperatorBusinessName}}</div>
                        </div>
                        <div class="row px-3 px-md-0 mb-3">
                            <div class="col-md-6 mt-3">
                                <div class="row d-md-none">
                                    <div class="col-6 pe-0"><b>{{ "pt_operator_business_name_agency_report" |
                                            translate}}:</b>
                                    </div>
                                    <div class="col-6">{{report.RoundtripTicket.PtOperatorBusinessName}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.PassengerDepartureDate}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme polaska:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.PassengerDepartureTime}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "passcode" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.AssignId}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "platform" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.PlatformNumber}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "seat" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.SeatNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.Price}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.Commission}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price_pr" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.TransportPrice}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "st_service" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.TicketPTTaxesAmount}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "loyalty_discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.LoyaltyUsedAmount}}
                                    </div>
                                </div>
                                <div class="row d-none d-md-flex">
                                    <div class="col-8 pe-0"><b>{{ "total" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.TotalPrice}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-md-none lh-base font-20 fw-bold mb-2">
                            <div class="col text-right"> {{ "total" | translate}}:
                                {{report.RoundtripTicket.TotalPrice}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-xs-10 col-md-9 col-lg-6 px-0"
                    *ngIf="report.Ticket?.ConnectedTicketNumber != null || report.RoundtripTicket?.ConnectedTicketNumber != null">
                    <div *ngIf="report.RoundtripTicket.ConnectedTicketNumber != null "
                        class="agency-ticket-preview_ticket lh-sm">
                        <div class="row d-md-none lh-base col-xs-10 col-md-5">
                            <div class="text-center font-20 fw-bold">{{report.RoundtripTicket.RouteName}}</div>
                        </div>
                        <div class="row d-md-none lh-base justify-content-around font-20 fw-bold mb-4">
                            <div class="col"> {{report.RoundtripTicket.Date}} {{report.RoundtripTicket.Time}}</div>
                            <div class="col text-right"> {{ "direction" | translate}} 2</div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "ticket_number" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 text-right d-none d-md-block">
                                <div class="row"><b>{{ "direction" | translate}} 2</b></div>
                            </div>
                        </div>
                        <div class="row px-3 px-md-0">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.Date}}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme:</b></div>
                                    <div class="col-6 text-right">{{report.RoundtripTicket.Time}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-none d-md-flex mt-3 px-3 px-md-0">
                            <div class="col-3"><b>{{ "route_name" | translate}}:</b></div>
                            <div class="col">{{report.RoundtripTicket.ConnectedTicketRouteName}}</div>
                        </div>
                        <div class="row d-none d-md-flex px-3 px-md-0">
                            <div class="col-3"><b>{{ "pt_operator_business_name_agency_report" | translate}}:</b></div>
                            <div class="col">{{report.RoundtripTicket.ConnectedTicketPtOperatorBusinessName}}</div>
                        </div>
                        <div class="row px-3 px-md-0 mb-3">
                            <div class="col-md-6 mt-3">
                                <div class="row d-md-none">
                                    <div class="col-6 pe-0"><b>{{ "pt_operator_business_name_agency_report" |
                                            translate}}:</b>
                                    </div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketPtOperatorBusinessName}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "date" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketPassengerDepartureDate}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>Vreme polaska:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketPassengerDepartureTime}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "passcode" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketAssignId}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "platform" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketPlatformNumber}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-6 pe-0"><b>{{ "seat" | translate}}:</b></div>
                                    <div class="col-6">{{report.RoundtripTicket.ConnectedTicketSeatNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">{{report.RoundtripTicket.ConnectedTicketPrice}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.RoundtripTicket.ConnectedTicketCommission}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "price_pr" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.RoundtripTicket.ConnectedTicketTransportPrice}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "st_service" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.RoundtripTicket.ConnectedTicketPTTaxesAmount}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 pe-0"><b>{{ "loyalty_discount" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.RoundtripTicket.ConnectedTicketLoyaltyUsedAmount}}
                                    </div>
                                </div>
                                <div class="row d-none d-md-flex">
                                    <div class="col-8 pe-0"><b>{{ "total" | translate}}:</b></div>
                                    <div class="col-4 text-right ps-0">
                                        {{report.RoundtripTicket.ConnectedTicketTotalPrice}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-md-none lh-base font-20 fw-bold mb-2">
                            <div class="col text-right"> {{ "total" | translate}}:
                                {{report.RoundtripTicket.ConnectedTicketTotalPrice}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <a class="btn btn-primary  mx-auto" (click)="download(report)">{{
                        "pickup_ticket" | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>