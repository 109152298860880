<div class="title margin-bottom-30">
    <h1>Izaberite povratnu kartu</h1>
</div>
<div class="container">
    <div class=" row mb-4">
        <div class="text-center">{{ "return_ticket" | translate }}: <strong>{{ticket.RideDescription}}</strong>
            ({{ticket.TicketNumber}})</div>
    </div>

    <cmd-roundtrip-dated-journey-select [dateJourney]="datedJourneyInfo" [roundtripDatedJourneyId]="0"
        (onSelectRoundtripDatedJourneyId)="roundtripDatedJourney = $event">
    </cmd-roundtrip-dated-journey-select>

    <div class="text-center">
        <button type="button" class="btn btn-outline-primary margin-bottom-10 me-1" (click)="routerService.back()">
            {{ "back" | translate }}</button>
        <button type="button" class="btn btn-primary margin-bottom-10 ms-1"
            [disabled]="!roundtripDatedJourney || isClickedYes" (click)="openModal()">{{
            "confirm_departure" | translate }}</button>
    </div>
</div>