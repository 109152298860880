<div class="container p-3">
    <div class="section my-5">
        <div class="row text-center">
            <div class="col-12">
                <div class="font-20 fw-bolder mb-3">{{ "passenger" | translate }}</div>
                <div class="lh-sm">
                    <div><strong>{{ "name_multipage" | translate }}:</strong> {{ticket.PassengerName}}</div>
                    <div><strong>{{ "category" | translate }}:</strong> {{ticket.Category}}</div>
                    <div><strong>{{ "legitimation_number" | translate }}:</strong> {{ticket.PassId}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <div class="font-20 fw-bolder my-3">{{ "multiride_ticket" | translate}}</div>
                <div class="font-18 mb-3" *ngIf="!ticket.Zone">{{ticket.Route}}</div>
                <div class="font-18 mb-3" *ngIf="ticket.Zone">{{ticket.Zone}}</div>
                <div class="lh-sm">{{ticket.FromDate | date: 'dd.MM.yyyy'}} - {{ticket.ToDate | date: 'dd.MM.yyyy'}}
                </div>
                <div class="lh-sm">{{ "price" | translate}}: {{ticket.Price | number : '1.2-2'}} RSD</div>
            </div>
        </div>
        <div class="col-12 text-center p-2 mt-3"><strong>{{ "total" | translate}}: {{ticket.Price | number :
                '1.2-2'}} RSD</strong></div>
    </div>
    <div class="row mt-5 mb-3">
        <div class="form-group d-flex flex-nowrap justify-content-center">
            <input type="checkbox" class="form-check-input mt-1" name="tnc" id="tnc"
                [(ngModel)]="isCheckedTermsAndConditions">
            <a href="javascript:;" class="ms-2 text-black fw-normal cursor-pointer text-underline-onhover"
                (click)="openModal('rights_obligations_popup')">
                {{ "i_am_informed_about" | translate }}
                {{ "terms_of_purchase_and_payment" | translate }}</a>
        </div>
    </div>
    <div class="row prev-next mb-4">
        <div class="col-6 text-right">
            <button class="btn btn-outline-primary" (click)="back()"> {{ "Nazad" |
                translate}}</button>

        </div>
        <form class="col-6 text-center" ngNoForm method="post" action="{{constants.PAYMENT_URL}}">
            <input type="hidden" name="clientid" [(ngModel)]="ticket.ClientId">
            <input type="hidden" name="amount" [(ngModel)]="ticket.Amount">
            <input type="hidden" name="oid" [(ngModel)]="ticket.OrderId">
            <input type="hidden" name="okUrl" [(ngModel)]="ticket.OkUrl">
            <input type="hidden" name="failUrl" [(ngModel)]="ticket.FailUrl">
            <input type="hidden" name="tranType" [(ngModel)]="ticket.TransactionType">
            <input type="hidden" name="instalment" [(ngModel)]="ticket.Installment">
            <input type="hidden" name="currency" [(ngModel)]="ticket.Currency">
            <input type="hidden" name="rnd" [(ngModel)]="ticket.Rnd">
            <input type="hidden" name="hash" [(ngModel)]="ticket.Hash">
            <input type="hidden" name="storetype" [(ngModel)]="ticket.StoreType">
            <input type="hidden" name="lang" [(ngModel)]="ticket.Lang">
            <input type="hidden" name="hashAlgorithm" [(ngModel)]="ticket.HashAlgorithm">
            <input type="hidden" name="shopurl" [(ngModel)]="ticket.Shopurl">
            <input type="hidden" name="encoding" value="utf-8">
            <div class="text-left">
                <button class="btn btn-primary" type="submit" [disabled]="!isCheckedTermsAndConditions">{{
                    "continue" | translate}}</button>
            </div>
        </form>
    </div>
</div>