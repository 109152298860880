<section id="invoice-section">
    <div class="container pt-5">
        <div class="row my-4 d-md-none">
            <div *ngIf="isSuccess" class="col text-center mb-2">
                <div class="fw-bolder fs-4">{{ "successfull_transaction"| translate}}</div>
                <div class="bill-text fs-5">{{ "your_account_is_in_charge"| translate}} <span class="fw-bolder">{{total
                        | number : '1.2-2'}} RSD</span></div>
                <div class="bill-text fs-6" *ngIf="loyaltyAmount">{{ "your_loyalty_card_is_charged"| translate}}
                    <strong>{{loyaltyAmount | number : '1.2-2'}} RSD</strong>
                </div>
            </div>
            <div *ngIf="!isSuccess" class="col text-center">
                <div class="fw-bolder fs-4">{{ "unsuccessfull_transaction"| translate}}</div>
            </div>
        </div>
        <div class="section m-4 p-3 p-md-5">
            <div class="row mx-auto justify-content-md-between">
                <div class="item-buyer col-md-4">
                    <div class="fw-bolder fs-5 mb-2">{{ "buyer" | translate }}</div>
                    <div>
                        <div><span class="fw-bolder">{{ "name" | translate }}:</span> {{buyerName}}</div>
                        <div><span class="fw-bolder">{{ "address" | translate }}:</span> {{buyerAddress}}</div>
                        <div><span class="fw-bolder">{{ "phone" | translate }}:</span> {{buyerPhone}}</div>
                    </div>
                </div>
                <div class="mt-4 mt-md-0 item-seller col-md-4">
                    <div class="fw-bolder fs-5 mb-2">{{ "seller" | translate }}:</div>
                    <div class="details">
                        <div>Niš-ekspres doo Niš</div>
                        <!--<div>{{ "company_address" | translate }}</div>-->
                        <div>Stevana Sinđelića 59</div>
                        <div>18000 Niš, Srbija</div>
                        <div>+381 18 255 177</div>
                        <div>+381 18 255 666</div>
                        <div>http://www.nis-ekspres.rs</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4 d-none d-md-flex">
                <div *ngIf="isSuccess" class="col text-center mb-2">
                    <div class="fw-bolder fs-4">{{ "successfull_transaction"| translate}}</div>
                    <div class="bill-text fs-5">{{ "your_account_is_in_charge"| translate}} <span
                            class="fw-bolder">{{total | number : '1.2-2'}} RSD</span></div>
                    <div class="bill-text fs-6" *ngIf="loyaltyAmount">{{ "your_loyalty_card_is_charged"| translate}}
                        <strong>{{loyaltyAmount | number : '1.2-2'}} RSD</strong>
                    </div>
                </div>
                <div *ngIf="!isSuccess" class="col text-center">
                    <div class="fw-bolder fs-4">{{ "unsuccessfull_transaction"| translate}}</div>
                </div>
            </div>
            <div class="row mt-4 mx-auto justify-content-md-start">
                <div class="col-md-6 item-seller">
                    <div class="fw-bolder fs-5 mb-2">{{ "transaction_info" | translate}}:</div>
                    <div class="details">
                        <div><span class="fw-bolder">{{ "transaction_date" | translate }}:</span> {{datetime}}</div>
                        <div><span class="fw-bolder">{{ "order_id" | translate }}:</span> {{orderId}}</div>
                        <div><span class="fw-bolder">{{ "authorization_code" | translate }}:</span> {{authCode}}</div>
                        <div><span class="fw-bolder">{{ "payment_status" | translate }}:</span> {{response}}</div>
                        <div><span class="fw-bolder">{{ "transaction_status_code" | translate }}:</span>
                            {{procReturnCode}}
                        </div>
                        <div><span class="fw-bolder">{{ "transaction_id" | translate }}:</span> {{transId}}</div>
                        <div><span class="fw-bolder">{{ "status_code_for_3d_transaction" | translate }}:</span>
                            {{mdStatus}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col text-center">
                    <div *ngIf="isSuccess"> {{ "transaction_report_will_be_sent_to_email" | translate}}: <a
                            href="email:#">{{email}}</a></div>

                </div>
            </div>
        </div>
        <div class="row text-center py-4">
            <div>
                <a href="#/index" class="btn btn-primary">{{ "go_to_home_page" | translate}}</a>
            </div>

        </div>
    </div>
</section>