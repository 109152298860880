import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { BaseImports } from 'src/libs/base-imports';
import { TranslateModule } from '@ngx-translate/core';
declare const gtag: Function;

@Component({
  selector: 'app-payment-status',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './payment-status.page.html',
  styleUrls: ['./payment-status.page.scss']
})
export class PaymentStatusPage extends BaseImports implements OnInit, OnDestroy {
  subscription: Subscription[] = [];

  isSuccess: boolean = false;
  transId!: string;
  authCode!: string;
  orderId!: string;
  datetime!: string;
  mdStatus!: string;
  procReturnCode!: string;
  response!: string;
  total: number;
  email!: string;
  loyaltyAmount?: number;

  buyerName!: string;
  buyerAddress!: string;
  buyerPhone!: string;

  constructor(private injector: Injector) {
    super(injector);
    this.total = 0;
    var reservation = this.storageService.getTicket();
    if (!reservation) {
      this.routerService.navigate("index");
      return;
    }

    this.total = Number(reservation?.Amount);
    this.email = reservation?.Email ?? "";
    this.loyaltyAmount = reservation?.LoyaltyAmount;

    if (this.authenticationService.isAuthenticated()) {
      var userData = this.storageService.getUserData();
      this.buyerName = userData?.Name ?? "";
      this.buyerAddress = userData?.Address ?? "";
      this.buyerPhone = userData?.Phone ?? "";
      this.email = userData?.Email ?? "";
    }
  }

  ngOnInit() {
    this.subscription.push(this.route.params.subscribe(params => {
      this.isSuccess = (params['isSuccess'] == "false" ? false : true);
      this.transId = params['transId'] == "null" ? "-" : params['transId'];
      this.authCode = params['authCode'] == "null" ? "-" : params['authCode'];
      this.datetime = params['datetime'] == "null" ? "-" : params['datetime'];
      this.orderId = params['orderId'] == "null" ? "-" : params['orderId'];
      this.mdStatus = params['mdStatus'] == "null" ? "-" : params['mdStatus'];
      this.procReturnCode = params['procReturnCode'] == "null" ? "-" : params['procReturnCode'];
      this.response = params['response'] == "null" ? "-" : params['response'];

        if (this.isSuccess){
          try {
            gtag('event', 'conversion', 
            {
              'send_to': 'AW-16606794858/d2MwCLP0srkZEOqo3u49',
              'transaction_id': this.orderId
            });
          }
          catch(e: any){
            console.log("Conversion event ERROR: ");
            console.log(JSON.stringify(e));
          }
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.forEach(x => x.unsubscribe());
    this.storageService.clearTicket();
  }
}
