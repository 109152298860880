import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  selector: 'closed-session',
  templateUrl: './closed-session.page.html',
  styleUrls: ['./closed-session.page.scss'],
})
export class ClosedSessionPage extends BaseImports implements OnInit, OnDestroy {

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    
  }
}
