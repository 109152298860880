export class MultirideTicketDo {
  PassengerName: string = "";
  Category: string = "";
  PassId: string = "";
  FromDate!: Date;
  ToDate!: Date;
  Price!: number;
  Route: string = "";
  Zone: string = "";
  PeriodId!: number;

  OrderCode: string = "";
  Amount: string = "";
  ClientId: string = "";
  Currency: string = "";
  FailUrl: string = "";
  HashAlgorithm: string = "";
  Installment: string = "";
  Lang: string = "";
  OkUrl: string = "";
  OrderId: string = "";
  Rnd: string = "";
  StoreType: string = "";
  TransactionType: string = "";
  Shopurl: string = "";
  RemainingSecond: string = "";
  Hash: string = "";
}
