<div class="title">
    <h1>{{ 'multiride_tickets' | translate }}</h1>
</div>
<div class="row justify-content-center">
    <div class="col-md-6 col-xs-12">
        <div class="row justify-content-center">
            <div class="col-md-6 col-xs-12" *ngFor="let pass of userData?.PassengersInfo">
                <div class="row justify-content-center text-center margin-top-30">
                    <div class="col-8">
                        <label>{{ "name" | translate}}</label>
                        <input type="text" class="form-control" value="{{pass.PassengerName}}" disabled>
                        <label>{{ "legitimation_number" | translate}}</label>
                        <input type="text" class="form-control" value="{{pass.PassengerPassId}}" disabled>
                        <button *ngIf="isPassExists(pass)" type="button"
                            class="btn btn-primary mt-3 py-1 min-width-100px" (click)="details(pass)">{{ "continue" |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 margin-top-30 text-center" [innerHtml]="'pass_list_note' | translate | safeHtml"></div>
</div>