import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbDateStruct, NgbModal, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subscription } from 'rxjs';
import { ModalComponent } from 'src/components/modal/modal.component';
import { ModalDo } from 'src/dos/modal.do';
import { PointViewDto } from 'src/dtos/common/point-view.dto';
import { SignInDto } from 'src/dtos/sign-in/sign-in.dto';
import { BaseImports } from "src/libs/base-imports";
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgbTypeaheadModule, NgbDatepickerModule, RouterModule],
  selector: 'index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class IndexPage extends BaseImports implements OnInit, OnDestroy {
  submitted = false;
  subscriptions: Subscription[] = [];
  //model!: NgbDateStruct;

  formatter = (result: { Caption: string }) => result.Caption;
  points: PointViewDto[] = [];
  startPoints: PointViewDto[] = [];
  endPoints: PointViewDto[] = [];

  notices!: SafeHtml;
  hasNotice: boolean = false;

  form = this.formBuilder.group({
    startPoint: [<undefined | PointViewDto>undefined, Validators.required],
    endPoint: [<undefined | PointViewDto>undefined, Validators.required],
    date: [new Date(), Validators.required],
  });

  constructor(private injector: Injector, private sanitizer: DomSanitizer, private modalService: NgbModal) {
    super(injector);
    this.getNotices();
  }

  ngOnInit() {
    this.subscriptions.push(this.webapiCommonService.getPoints().subscribe(res => {
      this.startPoints = res.filter(x => x.IsStartPoint);
      this.form.controls['startPoint'].setValue(this.startPoints.find(x => x.IsDefaultStartPoint) ?? this.startPoints[0]);
      this.endPoints = res.filter(x => x.IsEndPoint);
      this.points = res;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  getNotices() {
    this.subscriptions.push(this.webapiCommonService.GetNotices().subscribe((res) => {
      this.hasNotice = (res.Content == "" || res.Content == null) ? false : true;
      this.notices = this.sanitizer.bypassSecurityTrustHtml(res.Content);
    }));
  }


  startPointsAutocomplete: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 0 ? []
      : this.filterPoints(this.startPoints, term))
    )


  endPointsAutocomplete: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 1 ? []
      : this.filterPoints(this.endPoints, term))
    )

  filterPoints(points: PointViewDto[], trem: string) {
    return <PointViewDto[]>points.map(x => {
      return {
        ...x,
        Index: this.getIndexCaptionMatch(trem, x)
      }
    }).filter(v => v.Index > -1).sort((a, b) => { return a.Index - b.Index }).slice(0, 10);
  }

  getIndexCaptionMatch(term: string, point: PointViewDto): number {
    var caption = this.commonService.normalizeLatinText(point.Caption).toLowerCase();
    var abbr = this.commonService.normalizeLatinText(point.Abbreviation).toLowerCase();
    var term = this.commonService.normalizeLatinText(term).toLowerCase();

    if (caption == term || abbr == term) {
      return -1;
    }

    var captionIndex = caption.indexOf(term);
    if (captionIndex == -1) {
      return Number.MIN_VALUE;
    }
    else {
      return captionIndex;
    }
  }

  search() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.routerService.navigate("journeys", [this.form.controls['startPoint'].value?.Id, this.form.controls['endPoint'].value?.Id, this.commonService.formatDate(this.form.controls['date'].value ?? new Date(), false, "-")]);
  }

  link(page: string) {
    this.routerService.navigate(page);
  }
  open(content: string, title: string = "") {
    var body: ModalDo = {
      content: content,
      title: title
    };

    const ref = this.modalService.open(ModalComponent, { size: 'xl', scrollable: true });
    ref.componentInstance.setText(body);
  }
}


