import { Component, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbModal, NgbModule, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ModalDo } from 'src/dos/modal.do';
import { SignInComponent } from '../sign-in/sign-in.component';
import { ModalComponent } from '../modal/modal.component';
import { AlertComponent } from '../alert/alert.component';
import { SharedDo } from 'src/dos/shared/shared.do';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'cmp-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModule, NgbDropdownModule, SignInComponent, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: []
})
export class HeaderComponent extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild(NgbDropdown) dropdown!: NgbDropdown;
  @ViewChild('content') modal!: NgbActiveModal;

  constructor(private injector: Injector, private modalService: NgbModal) {
    super(injector);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.sharedService.on(this.constants.EV_SHOW_LOGIN_MODAL, this.openLoginModal.bind(this)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  openLoginModal(smallScreen: SharedDo) {
    if (smallScreen.Data) {
      this.toggleModal(this.modal);
    }
    else {
      this.dropdown.open()
    }
  }

  toggleModal(content: NgbActiveModal | TemplateRef<any>): void {
    this.modalService.open(content, { fullscreen: true, modalDialogClass: 'navbar-modal', ariaLabelledBy: 'modal-basic-title' });
  }

  open(content: string, title: string = "") {
    var body: ModalDo = {
      content: content,
      title: title
    };

    const ref = this.modalService.open(ModalComponent, { size: 'xl', scrollable: true });
    ref.componentInstance.setText(body);
  }

  setLanguage(lng: string) {
    this.localizationService.setLanguage(lng);
  }

  logout() {
    let url = window.location.href.split('/');
    this.subscriptions.push(this.authenticationService.logout().subscribe(() => {
      if (url[url.length - 1] != "index") {
        this.routerService.navigate("index");
      }
      else {
        window.location.reload();
      }
    }));
  }
}
