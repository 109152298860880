import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';

@Injectable()
export class RouterService {

    constructor(private router: Router, private location: Location, private viewportScroller: ViewportScroller) { }

    navigate(page: string, params: any[] = []) {
        this.router.navigate(["/" + page, ...params]).then(() => {
            this.viewportScroller.scrollToPosition([0, 0]);
        });
    }

    back() {
        // this.location.path();
        this.location.back();

        //this.router.navigate("..");
    }
}