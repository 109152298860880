import { Component, Injector, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDo } from 'src/dos/modal.do';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmd-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  public modalData = signal<ModalDo>(new ModalDo());
  public isConfirm = signal<boolean>(false);

  constructor(public activeModal: NgbActiveModal) {
  }

  public setText(model: ModalDo) {
    this.modalData.set(model);
  }


  public isConfirmModal(data: boolean) {
    this.isConfirm.set(data);
  }
}
