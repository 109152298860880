import { registerLocaleData } from '@angular/common';
import sr from '@angular/common/locales/sr-Latn';
import en from '@angular/common/locales/en';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withHashLocation  } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Constants } from './app.constants';
import { routes } from './app.routes';

// Services
import { LoggerService } from 'src/services/logger.service';
import { RouterService } from 'src/services/router.service';
import { CommonService } from 'src/services/common.service';
import { SharedService } from 'src/services/shared.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { LocalizationService } from 'src/services/localization.service';
import { StorageService } from 'src/services/storage.service';
import { WebApiCommonService } from 'src/services/webapi-common.service';
import { WebapiIdentityService } from 'src/services/webapi-identity.service';
import { WebapiJourneysService } from 'src/services/webapi-journeys.service';
import { interceptorProviders } from 'src/services/interceptors/app.interceptors';
import { WebapiReservationService } from 'src/services/webapi-reservation.service';
import { WebapiTicketService } from 'src/services/webapi-ticket.service';
import { WebapiMultirideService } from 'src/services/webapi-multiride.service';
import { WebapiSessionService } from 'src/services/webapi-session.service';

// Componenets
import { ModalComponent } from 'src/components/modal/modal.component';
import { AlertComponent } from 'src/components/alert/alert.component';
import { SafeHtmlPipe } from 'src/pipes/safe-html.pipe';

// Pipes
import { provideAnimations } from '@angular/platform-browser/animations';

registerLocaleData(sr);
registerLocaleData(en);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, Constants.API_ENDPOINT + Constants.API_VERSION + "translations/", '');
}

export const appConfig: ApplicationConfig = {
  providers: [
    RouterService,
    SharedService,
    AuthenticationService,
    CommonService,
    LoggerService,
    StorageService,
    WebApiCommonService,
    LocalizationService,
    ModalComponent,
    AlertComponent,
    WebapiIdentityService,
    WebapiJourneysService,
    WebapiReservationService,
    WebapiTicketService,
    WebapiMultirideService,
    WebapiSessionService,
    
    provideRouter(routes, withHashLocation()),
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([])
    ),
    interceptorProviders
  ],
};


