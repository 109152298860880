import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { BaseImports } from 'src/libs/base-imports';
import { Table } from 'src/libs/table';
import { ModalComponent } from 'src/components/modal/modal.component';
import { ModalDo } from 'src/dos/modal.do';
import { RoundtripDatedJourneySelectComponent } from 'src/components/roundtrip-dated-journey-select/roundtrip-dated-journey-select.component';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto';
import { TicketVerifyFilterDto } from 'src/dtos/ticket/ticket-verify-filter.dto';
import { Subscription } from 'rxjs';
import { TicketVerifyViewDto } from 'src/dtos/ticket/ticket-verify-view.dto';
import { TicketDto } from 'src/dtos/ticket/ticket.dto';

@Component({
  selector: 'app-open-journey-ticket-create',
  standalone: true,
  imports: [CommonModule, RoundtripDatedJourneySelectComponent, TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './open-journey-ticket-create.page.html',
  styleUrls: ['./open-journey-ticket-create.page.scss'],
  providers: [
    //{ provide: NgbDateAdapter, useClass: CustomAdapter },
    //{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class OpenJourneyTicketCreatePage extends BaseImports implements OnInit, OnDestroy {
  roundtripDate: Date;
  isClickedYes: boolean;
  ticket!: TicketVerifyViewDto;
  forwardTicket!: TicketDto;
  table: Table<TicketVerifyViewDto>;
  minDate;

  roundtripDatedJourney?: DatedJourneyDto;
  datedJourneyInfo!: DatedJourneyDto;
  subscriptions: Subscription[] = [];

  constructor(private injector: Injector, private modalService: NgbModal) {
    super(injector);
    this.isClickedYes = false;
    this.table = new Table(1, 10);
    this.roundtripDate = new Date();
    var data = this.storageService.getOpenJourneyTickets();
    if (!data || data == null) {
      this.routerService.navigate("user-tickets-preview");
      return;
    }

    this.ticket = data;
    const points = this.ticket.RideDescription.split("-");
    this.datedJourneyInfo = {
      StartPointId: this.ticket.EndPointId,
      EndPointId: this.ticket.StartPointId,
      StartPointName: points[1],
      EndPointName: points[0],

      DatedJourneyId: 0,
      PassengerDepartureTime: new Date(),
      PassengerArrivalTime: new Date(),
      IsConnectedJourney: false,
      IsPromo: this.ticket.IsPromo
    }

    this.minDate = {
      day: this.roundtripDate.getDate(),
      month: this.roundtripDate.getMonth() + 1,
      year: this.roundtripDate.getFullYear()
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
  ngOnInit(): void {
    this.subscriptions.push(this.webapiTicketService.getForwardTicket(this.ticket.TicketId).subscribe(res => {
      this.forwardTicket = res;
      this.roundtripDate = res.Arrival > this.roundtripDate ? res.Arrival : this.roundtripDate;
      this.minDate = {
        day: this.roundtripDate.getDate(),
        month: this.roundtripDate.getMonth() + 1,
        year: this.roundtripDate.getFullYear()
      };
    }));
  }

  openModal() {
    var body: ModalDo = {
      content: this.translateService.instant("do_you_want_to_confirm_date_of__return_ticket_for") + " " + this.commonService.formatDateTime(this.roundtripDatedJourney?.PassengerDepartureTime ?? new Date()) + "?",
      title: ""
    };

    const ref = this.modalService.open(ModalComponent, { size: 'md', scrollable: true });
    ref.componentInstance.setText(body);
    ref.componentInstance.isConfirmModal(true);

    ref.result.then((result: boolean) => {
      if (result) {
        this.verify();
        this.isClickedYes = true;
      }
    }, () => { });
  }

  verify() {
    if (this.roundtripDatedJourney == null) {
      this.commonService.alertWarning("select_roundtrip_journey");
      return;
    }

    var data = new TicketVerifyFilterDto();
    data.DatedJourneyId = this.roundtripDatedJourney.DatedJourneyId;
    data.EndPointId = this.ticket.EndPointId;
    data.StartPointId = this.ticket.StartPointId;
    data.OrderCode = this.ticket.OrderCode;
    data.TicketID = this.ticket.TicketId;
    this.subscriptions.push(this.webapiTicketService.verifyTicket(data).subscribe(res => {
      if (res.IsSuccess == false) {
        this.commonService.alertError(res.Reason ?? "");//this.translateService.instant("ticket_is_not_verified")
      }
      else {
        let user = this.storageService.getUserData();
        this.commonService.alert(this.translateService.instant("ticket_has_been_created_and_sent_to_your_email") + "  " + user?.Email + ".");

        this.routerService.navigate('index');
      }
    }));
  }
}
