import { DatedJourneyPriceViewDto } from "./dated-journey-price-view.dto";

export class DatedJourneyDetailsViewDto {
  IsValid: boolean = true;
  Message: string = "";
  Prices: DatedJourneyPriceViewDto[] = [];
  Privilegies: any[] = [];
  IsRoundtripEnabled: boolean = true;
  MinTicketPrice: number = 0;

  IsLoyaltyAvailable: boolean = false;
  //LoyaltyBalance: number = 0;
  //LoyaltyMaxInPercents: number = 100;
  //LoyaltyMinAmount: number = 0;

  constructor() {
    this.Prices = [];
  }
}
