import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  filter,
  finalize,
  Observable,
  throwError,
  take,
  BehaviorSubject,
  switchMap
} from 'rxjs';
import { BaseImports } from 'src/libs/base-imports';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';
import { LoggerService } from '../logger.service';
import { RouterService } from '../router.service';
import { CommonService } from '../common.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  token?: string | null;
  includeContentType: boolean = false;
  omitCalls = ['Authentication', 'token'];
  skipInterceptor = false;
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(private loggerService: LoggerService, private routerService: RouterService, private commonService: CommonService, private authenticationService: AuthenticationService) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.omitCalls.forEach((api) => {
      if (req.url.includes(api)) {
        this.skipInterceptor = true;
      }
    });

    this.token = this.authenticationService.getAccessToken();
    if (!this.skipInterceptor) {
      return <Observable<HttpEvent<any>>>next.handle(this.addAuthToken(req, this.token ?? "")).pipe(
        catchError((requestError: HttpErrorResponse) => {
          if (requestError && requestError.status === 401) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter((x) => x !== null),
                take(1),
                switchMap((token) => {
                  return <Observable<HttpEvent<any>>>next.handle(this.addAuthToken(req, token));
                })
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return <Observable<HttpEvent<any>>>this.authenticationService.refreshToken().pipe(
                //pipe map treba da vrati taj token
                switchMap(() => {
                  const newToken = this.authenticationService.getAccessToken();
                  this.token = newToken;
                  this.refreshTokenSubject.next(newToken);
                  return <Observable<HttpEvent<any>>>next.handle(this.addAuthToken(req, newToken!));
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                  this.commonService.hideLoader();
                })
              );
            }
          } else {
            return <Observable<HttpEvent<any>>>this.handleError(req, requestError);
          }
        })
      );
    }
    this.skipInterceptor = false;

    return next.handle(req);
  }

  addAuthToken(req: HttpRequest<any>, token: string) {
    let headers = req.headers.set('Authorization', 'Bearer ' + token);
    //req.headers.set('Culture',this.localizationService.getLanguage().LanguageCode);

    this.includeContentType = !(req.body instanceof FormData && req.body?.get('file'));

    if (this.includeContentType) {
      headers = headers.append('Content-Type', 'application/json');
    }
    return req.clone({ headers: headers });
  }

  handleError(req: HttpRequest<any>, error: HttpErrorResponse) {
    let errBody = new ResponseTemplateDto<any>();

    let errorMessage = 'Error';
    if (error.status == 403) {
      this.routerService.back();
      errorMessage = 'You do not have permision for access';
      this.commonService.alertError(errorMessage);
    }

    if (error.status != 400 && error.status >= 400 && error.status <= 500) {
      errBody = error.error ?? error.message;
      console.log(error.error, error.message)
      this.commonService.alertError(errBody.ErrorMessage ?? 'error')
      // alert( errBody.ErrorMessage?.replace('_', ' ') || 'Error' );
      return this.logAndThrowError(
        errBody,
        req.method +
        JSON.stringify(req.urlWithParams) +
        JSON.stringify(req.body)
      );
    }

    errBody.IsSuccess = false;
    errBody.Data = false;

    if (error.status == 0) {
      // NOTE: It will be shown for every request failure.
      errorMessage = 'Webapi is offline';
      alert(errorMessage);
    }
    errBody.ErrorMessage = errorMessage;
    return this.logAndThrowError(
      errBody,
      req.method + JSON.stringify(req.urlWithParams) + JSON.stringify(req.body)
    );
  }

  logAndThrowError(errBody: any, origin?: string) {
    this.loggerService.errorObject(errBody, origin);
    return throwError(() => new Error(errBody.ErrorMessage));
  }
}

