import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto';
import { JourneyDo } from 'src/dos/journeys/journeys.do';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { DepartureDescriptionComponent } from '../departure-description/departure-description.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbPaginationConfig, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Table } from 'src/libs/table';


@Component({
  selector: 'cmd-roundtrip-dated-journey-select',
  standalone: true,
  imports: [CommonModule, TranslateModule, DepartureDescriptionComponent, FormsModule, NgbDatepickerModule, ReactiveFormsModule, NgbPaginationModule],
  templateUrl: './roundtrip-dated-journey-select.component.html',
  styleUrls: ['./roundtrip-dated-journey-select.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    NgbPaginationConfig
  ]
})
export class RoundtripDatedJourneySelectComponent extends BaseImports implements OnInit, OnDestroy {
  @Input() dateJourney!: DatedJourneyDto;
  @Input() roundtripDatedJourneyId: number = -1;
  @Output() onSelectRoundtripDatedJourneyId = new EventEmitter<DatedJourneyDto>();
  datedJourneys: Table<DatedJourneyDto>;
  date: Date = new Date();

  subscriptions: Subscription[] = [];
  roundtripDatedJourney?: DatedJourneyDto;
  inProgress: boolean = false;

  constructor(private injector: Injector, config: NgbPaginationConfig) {
    super(injector);
    this.datedJourneys = new Table<DatedJourneyDto>(1, 5, config);
  }
  ngOnInit(): void {
    this.date = this.dateJourney.PassengerArrivalTime ?? this.dateJourney.PassengerDepartureTime;
    this.OnChangeDate();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  mapDatedJourney(data: DatedJourneyDto) {
    return new JourneyDo(data);
  }

  OnChangeDate() {
    var dateStr = this.commonService.formatDate(this.date, true);
    this.inProgress = true;
    this.subscriptions.push(
      this.webapiJourneysService.getJourneys(this.dateJourney.EndPointId, this.dateJourney.StartPointId, dateStr).subscribe((res) => {
        this.datedJourneys.setData(res.filter(y => {
          return (!this.dateJourney.IsPromo || y.IsPromo || this.storageService.isAgency()) && (new Date(y.PassengerDepartureTime) >= (new Date(this.dateJourney.PassengerArrivalTime) ?? new Date(this.dateJourney.PassengerDepartureTime)));
        }).map(x => {
          var result: DatedJourneyDto = {
            ...x,
            StartPointName: this.dateJourney.EndPointName,
            EndPointName: this.dateJourney.StartPointName,
          };
          return result;
        }));
        this.roundtripDatedJourney = res.find(x => x.DatedJourneyId == this.roundtripDatedJourneyId);
        this.inProgress = false;
      }))
  }

  OnSelectDatedJourney(ev: DatedJourneyDto) {
    this.onSelectRoundtripDatedJourneyId.emit(ev)//.target?.value
  }
}
