import { Component, OnInit, Injector, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet, NavigationEnd, NavigationStart, Event } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from 'src/components/header/header.component';
import { SpinnerComponent } from 'src/components/spinner/spinner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from 'src/components/footer/footer.component';
import { Constants } from './app.constants';
import { BaseImports } from "src/libs/base-imports";
import { filter, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, TranslateModule, HeaderComponent, SpinnerComponent, FooterComponent, ReactiveFormsModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseImports implements OnInit, OnDestroy {
  reservationInterval: ReturnType<typeof setInterval> | null = null;
  private _remainigReservationTime: string = "";
  subscriptions: Subscription[] = [];
  title = 'transportation-web-tickets';
  navigationEnd: Observable<NavigationEnd>;;
  constructor(private injector: Injector, private router: Router, @Inject(DOCUMENT) private document: Document) {
    super(injector);
    this.navigationEnd = router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    this.subscriptions.push(this.sharedService.on(Constants.EV_RESERVATION_CREATED, this.startReservationTimeout.bind(this)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit() {
    this.navigationEnd.subscribe((e: NavigationEnd) => {
      var url = e.url.split('/')[1];
      gtag('event', 'page_view', {
        page_title: url,
        page_path: e.urlAfterRedirects,
        page_location: this.document.location.href
      });

      if (url == "payment-status" || url == 'multiride-payment-status') {
        return;
      }
      else if (url == 'agency-ticket-preview') {
        this.stopReservationInterval();
      }
      else {
        this.webapiSessionService.isSessionOpen().subscribe(res => {
          if (!res.IsSuccess) {
            this.routerService.navigate('closed-session');
          }
        })
      }
    });
    this.startReservationTimeout();
  }

  startReservationTimeout() {
    if (this.reservationInterval != null) {
      this.stopReservationInterval();
    }
    this.reservationInterval = setInterval(() => {
      var expiredInSeconds = this.storageService.getReservationDuration();
      if (expiredInSeconds == null) {
        this.stopReservationInterval();
        return;
      }
      if (expiredInSeconds <= 0) {
        this.storageService.clearTicket();
        this.routerService.navigate('index');
        if (expiredInSeconds > -3) {
          this.commonService.alertWarning("purchase_time_expired");
        }

        this.stopReservationInterval();
      } else {
        var rem_minutes = Math.floor(expiredInSeconds / 60);
        var rem_seconds = (expiredInSeconds - (rem_minutes * 60)) % 60;
        this._remainigReservationTime = rem_minutes + ":" + (rem_seconds < 10 ? "0" + rem_seconds : rem_seconds);

        this.sharedService.broadcast(Constants.EV_REMAINIG_RESERVATION_TIME, this._remainigReservationTime);
      }
    }, 1000);
  }

  stopReservationInterval() {
    if (!this.reservationInterval) {
      return;
    }
    clearInterval(this.reservationInterval);
    this.sharedService.broadcast(Constants.EV_PURCHASE_TIME_EXPIRED);
    this.reservationInterval = null;
    this._remainigReservationTime = "";
  }
}
