import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { BaseImports } from 'src/libs/base-imports';
import { TicketViewDto } from 'src/dtos/ticket/ticket-view.dto';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-agency-ticket-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './agency-ticket-preview.page.html',
  styleUrls: ['./agency-ticket-preview.page.scss']
})
export class AgencyTicketPreviewPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  reports: TicketViewDto[] | null;

  constructor(private injector: Injector) {
    super(injector);
    this.reports = this.storageService.getAgencyTickets();
    if (!this.reports || this.reports.length == 0) {
      this.routerService.navigate("index");
    }
  }

  ngOnInit(): void {
    this.storageService.clearAgencyTickets();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  download(data: TicketViewDto) {
    this.subscriptions.push(this.webapiTicketService.downloadAgencyTicket(data.Ticket.TicketID).subscribe((res) => {
      var link = document.createElement('a');
      link.setAttribute("type", "hidden");
      link.download = 'Karta.pdf';
      link.href = window.URL.createObjectURL(res);
      document.body.appendChild(link);
      link.click();
    }));
  }
}
