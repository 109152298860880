import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SignInDto } from 'src/dtos/sign-in/sign-in.dto';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sign-in-component',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends BaseImports implements OnInit, OnDestroy {
  submitted = false;
  subscriptions: Subscription[] = [];

  form = this.formBuilder.group({
    Email: [<string | undefined>undefined, Validators.required],
    Password: [<string | undefined>undefined, Validators.required],
  });

  constructor(private injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  signIn() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    var data = new SignInDto();
    data = <SignInDto>this.form.value;
    this.authenticationService.login(data).subscribe((res) => {
      this.routerService.navigate("index");
      this.submitted = false;
    }, err => {
      this.form.reset();
    },
      () => {
        this.form.reset();
        this.submitted = false;

        window.location.reload();
      });
  }
}
