export class DatedJourneyPriceViewDto {
  PassengerTypeId!: number;
  PassengerTypeCaption!: string;
  //PassengerTypeNote!: string;
  //CommisionRate?: number;
  Price?: number;
  RoundtripPrice?: number;
  OriginalPrice?: number;
  OriginalRoundtripPrice?: number;
  //NetoPrice?: number;  //?
  //NetoRoundtripPrice?: number;  //?

  //ADD Discount

  constructor() { }
}
