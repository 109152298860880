<div class="d-flex flex-row mb-4">
    <div *ngIf="isWarrning || isError" class=" d-flex align-items-center text-right pe-3">
        <img *ngIf="isWarrning" width="30" src="../../assets/icons/warrning.png">
        <img *ngIf="isError" width="30" src="../../assets/icons/error.png">
    </div>
    <div class="d-flex align-items-center">
        <div [innerHtml]="text | translate"></div>
    </div>
</div>
<div class="col text-right m-1">
    <button type="button" class="btn btn-primary" (click)="activeModal.close(false)">Zatvori</button>
</div>