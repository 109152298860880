import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalDo } from 'src/dos/modal.do';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'agancy-ticket-modal-component',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './agancy-ticket-modal.component.html',
  styleUrls: ['./agancy-ticket-modal.component.scss']
})
export class AgancyTicketModalComponent {
  public modalData = signal<ModalDo>(new ModalDo());
  public note: string = "";

  constructor(public modal: NgbActiveModal) {
  }


  public setNote(note: string) {
    this.note = note;
  }

  public getNote() {
    return this.note;
  }
}

