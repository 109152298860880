import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { WebApiBaseService } from './webapi-base.service';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto'
import { DatedJourneyDetailsViewDto } from 'src/dtos/dated-journey/dated-journey-details-view.dto';
import { PriceDto } from 'src/dtos/price.dto';


@Injectable()
export class WebapiJourneysService extends WebApiBaseService {

    baseUrl = Constants.BASE_URL + "Journeys";
    constructor(private injector: Injector) {
        super(injector);
    }

    getJourneys(startPointId: number, endPointId: number, date: string): Observable<DatedJourneyDto[]> {
        return this.http.get<DatedJourneyDto[]>(this.baseUrl + "/" + date + "/" + startPointId + "/" + endPointId, this.getHttpParams());
    }


    getDetails(datedJourneyId: number, startPointId: number, endPointId: number): Observable<DatedJourneyDetailsViewDto> {
        return this.http.get<DatedJourneyDetailsViewDto>(this.baseUrl + "/Details/" + datedJourneyId + "/" + startPointId + "/" + endPointId, this.getHttpParams());
    }

    getPrice(datedJourneyId: number, startPointId: number, endPointId: number, quantity: number): Observable<PriceDto> {
        return this.http.get<PriceDto>(this.baseUrl + "/Price/" + datedJourneyId + "/" + startPointId + "/" + endPointId + "/" + quantity, this.getHttpParams(false));
    }
}
