<div class="d-flex justify-content-end min-height-90">
    <div class="remainig-time section text-nowrap " *ngIf="timeProgress !== 0">
        <div class="time">{{time}}</div>
        <div class="desc font-12">{{ "reservation_expires_on" | translate }}:
            <ngb-progressbar class="mb-3" type="dark" [value]="timeProgress" />
        </div>
    </div>
</div>
<div class="title mt-2 mb-5">
    <a href="./#notes" class="text-white text-uppercase">
        <h1 class="px-2">{{ 'think_ahead_travel_cheaper' | translate}}</h1>
    </a>
</div>
<div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="section col-12 col-md-8">
            <div class="d-flex flex-row justify-content-evenly flex-fill">
                <departure-description-component class="journeys-ride-description" [showDate]="true"
                    [datedJourney]="rideDescription()">
                </departure-description-component>
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column text-center ms-md-5  ms-auto me-1 min-width-100px my-auto ">
                        <span class="fs-5 fw-bolder  variable-font-size-20 " *ngIf="!inProgress"
                            [ngClass]="{'text-primary': originalPrice !== price}">&nbsp;{{price}}
                            <span class="font-14">RSD</span>&nbsp;</span>
                        <s class="journey-original-price variable-font-size-16 align-self-center"
                            *ngIf="originalPrice != price && !inProgress"><span class="m-1">&nbsp;{{originalPrice}}
                                &nbsp;<span class="font-14">RSD</span></span></s>
                        <div class=" spinner-border spinner-border-sm text-dark text-center mx-auto" role="status"
                            *ngIf="inProgress">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div class="d-flex align-middle text-center timer">
                        <div class="flex" *ngIf="originalPrice != price  && !inProgress">
                            <div class="m-progress-loader">
                                <div class="m-progress-loader--fill">
                                    <div class="number"> {{refreshPriceTime}} </div>
                                </div>
                                <div class="m-progress-loader--mask"></div>
                            </div>
                        </div>
                        <!-- <div class="my-auto"> {{refreshPriceTime}}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center margin-top-10"
            *ngIf="isAgency && (!storageService.getUsername() || storageService.getUsername() != 'Polazak.rs')">
            <div class="form-group col-lg-4 col-md-6 col-sm-8 col-12 text-center variable-font-size-16">
                <label for="ticket-category">{{ "category" | translate }}</label>
                <select name="ticket-category" id="ticket-category" formControlName="PassengerTypeId"
                    (change)="categoryChanged()" class="form-select">
                    <option *ngFor="let catergory of journeyDetails.Prices" value="{{catergory.PassengerTypeId}}">
                        {{catergory.PassengerTypeCaption}}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center margin-top-10" *ngIf="journeyDetails && !isStandardTicket && isAgency">
            <div class="form-group col-lg-4 col-md-6 col-sm-8 col-12 text-center variable-font-size-16"><label
                    for="privilege-type">{{ "privilegy_type" | translate }}</label>
                <select name="privilege-type" id="privilege-type" formControlName="PassengerPrivilegeId"
                    [disabled]="isStandardTicket" class="form-select">
                    <option *ngFor="let privilegy of journeyDetails.Privilegies" value="{{privilegy.Id}}">
                        {{privilegy.Caption}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center margin-top-10" *ngIf="journeyDetails && !isStandardTicket && isAgency">
            <div class="form-group col-lg-4 col-md-6 col-sm-8 col-12 text-center variable-font-size-16">
                <label for="privilege-number">{{ "privilegy_number" | translate }}</label>
                <input type="text" name="privilege-number" id="privilege-number" formControlName="PassengerPassId"
                    [disabled]="isStandardTicket" class="form-control">
            </div>
        </div>
        <div class="row justify-content-center margin-top-10">
            <div class="form-group col-lg-4 col-md-6 col-sm-8 col-12 text-center variable-font-size-16">
                <label>{{ "Smer" | translate }}</label>
                <div class="section d-flex flex-row py-1">
                    <div class="form-check mx-2 ps-0">
                        <input class="form-check-input ms-0 me-2" name="IsRoundtrip" type="radio" [value]="false"
                            id="one-way" [(ngModel)]="IsRoundtrip" [ngModelOptions]="{standalone: true}"
                            (change)="setPrice()">
                        <label class="form-check-label" for="one-way">
                            {{ "one_way" | translate }}
                        </label>
                    </div>
                    <div class="form-check  mx-2 ps-0">
                        <input class="form-check-input ms-0 me-2" name="IsRoundtrip" type="radio" [value]="true"
                            id="roundtrip" [(ngModel)]="IsRoundtrip" [ngModelOptions]="{standalone: true}"
                            (change)="setPrice()">
                        <label class="form-check-label" for="roundtrip">
                            {{ "roundtrip" | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center margin-top-10" *ngIf="IsRoundtrip && canCreateOpenReturnJourney">
            <div class="form-group d-flex flex-nowrap align-content-start justify-content-center">
                <input type="checkbox" class="form-check-input my-auto" name="loyalty" id="loyalty"
                    formControlName="IsNotOpenRoundtripJourney">
                <label for="loyalty" class="mx-2 pt-1 text-wrap align-self-baseline">
                    {{ "choose_return_date" | translate}}
                </label>
            </div>
        </div>
        <cmd-roundtrip-dated-journey-select [dateJourney]="ticket()"
            [roundtripDatedJourneyId]="form.controls['RoundtripDatedJourneyId'].value ?? -1"
            (onSelectRoundtripDatedJourneyId)="setRoundtripDatedJourneyId($event)"
            *ngIf="form.controls['IsNotOpenRoundtripJourney'].value && IsRoundtrip">
        </cmd-roundtrip-dated-journey-select>

        <div class="row justify-content-center margin-top-10">
            <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12 text-center variable-font-size-16">
                <label>{{ "number_of_tickets" | translate }}</label>
                <select name="ticket-qty" id="ticket-qty" formControlName="Quantity" class="form-select"
                    (change)="quantityChanged()">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center margin-top-10">
            <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12 text-center variable-font-size-16">
                <label>{{ "email" | translate }}</label>
                <input type="text" formControlName="Email" class="form-control" [disabled]="isAuthenticated" />
                <span class="error-messages" *ngIf="submitted && f(form, 'Email')">
                    {{ "required_field" | translate }}
                </span>
                <span class="error-messages" *ngIf="submitted && f(form, 'Email', 'pattern')">
                    {{ "email_is_not_valid" | translate }}
                </span>
            </div>
        </div>
        <!-- <div class="row justify-content-center margin-top-10">
            <div class="form-group col-lg-3 col-md-5 col-sm-6 col-12 text-center variable-font-size-16">
                <label>{{ "phone" | translate }} (opciono)</label>
                <input type="text" formControlName="Phone" class="form-control" />
            </div>
        </div> -->
        <div class="row justify-content-center  col" *ngIf="isAuthenticated">
            <div class="margin-top-10 text-center" *ngIf="isAuthenticated && isAgency && !showLoyaltyCard">
                <button type="button" class="btn btn-primary btn-sm" (click)="showLoyaltyCard = true">{{ "loyalty" |
                    translate }}</button>
            </div>
            <div *ngIf="isAuthenticated && showLoyaltyCard"
                class="form-group margin-top-10 col-lg-3 col-md-5 col-sm-6 col-12 text-center variable-font-size-16">
                <label>{{ "loyalty_card_number" | translate }}</label>
                <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="loyaltyUser().LoyaltyCardId"
                        [ngModelOptions]="{standalone: true}" [disabled]="loyaltyUser().LoyaltyUserId != null" />
                    <span class="input-group-text text-primary fw-bolder cursor-pointer"
                        *ngIf="!loyaltyUser().LoyaltyUserId && isAgency" (click)="searchLoyaltyCard()">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                    <span class="input-group-text text-primary fw-bolder cursor-pointer"
                        *ngIf="loyaltyUser().LoyaltyUserId && isAgency" (click)="cancelLoyaltyCard()">
                        <i class="fa-solid fa-x"></i>
                    </span>
                </div>
            </div>

            <div class="row margin-top-10" *ngIf="isAuthenticated && (loyaltyUser()?.LoyaltyUserId != null)">
                <div class="col-md-12 text-center" *ngIf="showLoyaltyCard">
                    {{ "user" | translate }}: <b>{{loyaltyUser().FirstName}} {{loyaltyUser().LastName}}</b>
                </div>
                <div class="col-md-12 text-center">
                    {{ "current_balance_on_the_loyalty_card" | translate }} <b>{{loyaltyUser().LoyaltyBalance | number
                        : '1.2-2' }}</b> RSD
                </div>
                <div class="col-md-12 text-center" *ngIf="loyaltyUser().LoyaltyBalance < loyaltyUser().MinAmountOfUse">
                    <span class="font-12"><i>{{ "min_amount_on_loyalty_card_should_be" | translate }}
                            {{loyaltyUser().MinAmountOfUse}} RSD </i></span>
                </div>
                <div class="col-md-12 text-center" *ngIf="loyaltyUser().LoyaltyBalance >= loyaltyUser().MinAmountOfUse">
                    <div class="row justify-content-center margin-top-10">
                        <div class="form-group d-flex flex-nowrap align-content-start justify-content-center">
                            <input type="checkbox" class="form-check-input my-auto" [(ngModel)]="useLoyatyBonus"
                                [ngModelOptions]="{standalone: true}">
                            <label for="loyalty" class="mx-2 pt-1 text-wrap align-self-baseline">
                                {{ "use_the_purchase_bonus" | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 text-center" *ngIf="useLoyatyBonus">
                        {{ "amount" | translate }}
                        <input type="number" min="{{loyaltyUser().MinAmountOfUse}}" max="getLoyaltyMaxAmount()"
                            class="rounded-5 px-1 width-90px" formControlName="LoyaltyAmount"
                            (blur)="validateLoyaltyAmount()" />
                        RSD
                        <span class="fa fa-info-circle margin-left-5" placement="top"
                            ngbTooltip="{{ 'minimum_amount_is' | translate }} {{loyaltyUser().MinAmountOfUse}} RSD, {{ 'maximum_amount_is' | translate }} {{getLoyaltyMaxAmount()}} RSD. ">
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row margin-top-30 justify-content-center">
            <div class="col-6 text-right">
                <button type="button" class="btn btn-outline-primary" (click)="back()">
                    {{ "back" | translate }}
                </button>
            </div>
            <div class="col-6">
                <button type="submit" class="btn btn-primary">{{ "buy" | translate }}</button>
            </div>
        </div>
    </form>
</div>