import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { SignUpDto } from 'src/dtos/sign-up/sign-up.dto';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { SignInDto } from 'src/dtos/sign-in/sign-in.dto';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgbDatepickerModule],
  selector: 'sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class SignUpPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  submitted = false;
  data: SignUpDto = new SignUpDto();
  form = this.formBuilder.group({
    Username: ['', Validators.required],
    Password: ['', Validators.required],
    Name: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: [''],
    Address: [''],
    Passport: [''],
    BirthDate: [this.commonService.formatDate(new Date(), false, '-')],
    WantLoyaltyProgram: [false]
  });

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.data = Object.assign(this.data, this.form.value);
    this.subscriptions.push(this.webapiIdentityService.registerUser(this.data).subscribe((res) => {
      if (res.IsSuccess) {
        var login = new SignInDto();
        login.Email = this.data.Username ?? "";
        login.Password = this.data.Password ?? "";
        this.authenticationService.login(login).subscribe((res) => { this.routerService.navigate("index") });
      }
      else {
        this.commonService.alertWarning(res.Reason ?? res.ErrorMessage ?? "error");
      }
    }));
  }

  showLoginModal() {
    this.sharedService.broadcast(this.constants.EV_SHOW_LOGIN_MODAL);
  }
}
