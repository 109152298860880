import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DataTableTemplateDto } from 'src/dtos/datatable-template.dto';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';
import { BaseImports } from 'src/libs/base-imports';
import { map, Observable } from 'rxjs';

@Injectable()
export class MapDataInterceptorService
  implements HttpInterceptor {
  omitCalls = ['Authentication'];
  skipInterceptor = false;

  constructor() {
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.omitCalls.forEach((api) => {
      if (req.url.includes(api)) {
        this.skipInterceptor = true;
      }
    });
    if (!this.skipInterceptor) {
      return next.handle(req).pipe(
        map((res) => {
          if (res instanceof HttpResponse) {
            if (res.body instanceof ResponseTemplateDto) {
              const data = res.body as ResponseTemplateDto<any>;
              if (!data.IsSuccess) {
                alert("Error: " + (data.ErrorMessage ?? ""));
              }
            }
          }
          return res;
        })
      );
    } else {
      this.skipInterceptor = false;
      return next.handle(req);
    }
  }
}
