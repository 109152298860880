<div data-bs-spy="scroll" data-bs-target="#navbar-example2">
    <div class="index_journey-picker-section">
        <div class="index_journey-picker transparent-section mx-0 mx-md-5">
            <div class="title text-shadow">{{ 'book_or_buy_ your_ticket' | translate}}</div>
            <form [formGroup]="form">
                <div class="pb-3">
                    <div class="input-group mb-2">
                        <span class="input-group-text white-input  pe-1">
                            <!-- <i class="fa fa-flag-checkered margin-right-5" aria-hidden="true"></i> -->
                            <img src="../../assets/icons/flag.svg" width="20" class="margin-right-5">
                            <div class="first-letter-uppercase">{{ "from" | translate }}</div>
                        </span>
                        <input type="text" class="form-control white-input text-start ps-1" formControlName="startPoint"
                            [ngbTypeahead]="startPointsAutocomplete" [resultFormatter]="formatter"
                            [inputFormatter]="formatter" [editable]="false">
                    </div>
                    <span class="error-messages row mx-3 " *ngIf="submitted && f(form, 'startPoint')">
                        {{ "required_field" | translate }}</span>
                </div>

                <div class="pb-3">
                    <div class="input-group mb-2">
                        <span class="input-group-text white-input pe-1">
                            <!-- <i class="fa fa-flag-checkered margin-right-5" aria-hidden="true"></i> -->
                            <img src="../../assets/icons/flag.svg" width="20" class="margin-right-5">
                            <div class="first-letter-uppercase">{{ "to" | translate }}</div>

                        </span>
                        <input type="text" class="form-control white-input text-start  ps-1" formControlName="endPoint"
                            [ngbTypeahead]="endPointsAutocomplete" [resultFormatter]="formatter"
                            [inputFormatter]="formatter" [editable]="false">
                    </div>
                    <span class="error-messages row mx-3" *ngIf="submitted && f(form, 'endPoint')">
                        {{ "required_field" | translate }}</span>
                </div>


                <div class="input-group mb-4">
                    <span class="input-group-text white-input  pe-1">
                        <!-- <i class="fa fa-calendar margin-right-5" aria-hidden="true"></i> -->
                        <img src="../../assets/icons/calendar.svg" width="20" class="margin-right-5">
                        {{ "date" | translate }}
                    </span>
                    <input type="text" ngbDatepicker #d="ngbDatepicker" formControlName="date"
                        class="form-control white-input  ps-1 text-start" (click)="d.toggle()" readonly>
                </div>
                <div class="w-100 d-flex">
                    <button type="submit" class="btn btn-primary mx-auto me-md-0" (click)="search()">
                        <img class="margin-right-5" src="../../assets/icons/search.svg" width="18">
                        {{ 'search' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="index_promo-text mx-0 mx-md-5 py-2" id="navbar-example2">
        <a href="#notes" class="text-uppercase">
            {{ "buy_in_advance_pay_up_to_30%_less" | translate }}!
        </a>
    </div>
    <div class="d-flex flex-row mt-5 justify-content-center align-items-stretch">
        <div class="section btn-gray me-2 me-md-5 my-0 d-flex flex-column cursor-pointer px-2 px-md-3"
            (click)="link('sign-up')" *ngIf="!authenticationService.isAuthenticated()">
            <i class="fa-solid fa-circle-user fs-4 mb-2"></i>
            <div class="text-center text-uppercase fw-bold my-auto index_section-text min-width-90px">{{ 'sign_up' |
                translate }}</div>
        </div>
        <div class="section btn-gray me-2 me-md-5 my-0 d-flex flex-column cursor-pointer px-2 px-md-3"
            (click)="link('profile')" *ngIf="authenticationService.isAuthenticated()">
            <i class="fa-solid fa-circle-user fs-4 mb-2"></i>
            <div class="text-center text-uppercase fw-bold my-auto index_section-text min-width-90px">{{ 'profile' |
                translate }}</div>
        </div>
        <a class="section btn-gray mx-2 mx-md-5 my-0 d-flex flex-column cursor-pointer px-2 px-md-3 text-black "
            href="http://www.nis-ekspres.rs/ekspresplus.html">
            <i class="fa-solid fa-credit-card fs-4 mb-2"></i>
            <div class="text-center text-uppercase fw-bold my-auto index_section-text min-width-90px">
                Ekspres+<br>loyalty
            </div>
        </a>
        <div class="section btn-gray ms-2 ms-md-5 my-0 d-flex flex-column align-items-stretch cursor-pointer  px-2 px-md-3"
            (click)="open('faq_popup')">
            <i class="fa-solid fa-circle-info fs-4 mb-2"></i>
            <div class="index_section-text  min-width-90px">{{ 'help' | translate }}</div>
        </div>
    </div>

    <div class="px-2 px-md-5 py-5 mx-auto" style="width: 90%;">
        <div class="p-4 section">
            <h1 class="mt-2">{{ "notices" | translate }}</h1>
            <div [innerHTML]="notices" class="index_notices p-2 p-md-4 overflow-hidden" id="notes"></div>
        </div>
    </div>
</div>