import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { SignUpDto } from 'src/dtos/sign-up/sign-up.dto';
import { RouterModule } from '@angular/router';
import { UserUpdateDto } from 'src/dtos/users/user-update.dto';
import { keyframes } from '@angular/animations';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule, NgbDatepickerModule],
  selector: 'profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ProfilePage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  submitted = false;
  form = this.formBuilder.group({
    Username: [{ value: '', disabled: true }, Validators.required],
    Name: ['', Validators.required],
    Email: ['', Validators.required],
    Phone: [''],
    Address: [''],
    Passport: [''],
    BirthDate: [<Date | undefined>undefined],
    WantLoyaltyProgram: [false],
    LoyaltyCardId: [{ value: <string | undefined>undefined, disabled: true }],
    LoyaltyBalance: [{ value: <number | undefined>undefined, disabled: true }]
  });

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(this.webapiIdentityService.getProfile().subscribe((res) => {
      this.form.patchValue(res);
      if (res.BirthDate == null) {
        this.form.controls['BirthDate'].setValue(undefined);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    //this.data = Object.assign(this.data, this.form.value);
    this.subscriptions.push(this.webapiIdentityService.updateProfile(<UserUpdateDto>this.form.value).subscribe((res) => {
      if (res.IsSuccess) {
        //alert("Profile is updated successfully");
        this.commonService.alert("changes_saved_successfully");
        this.routerService.navigate("index");
      }
    }));
  }
}
