<footer id="footer" class="mt-5">
    <div class="container">
        <div class="d-flex flex-column-reverse flex-md-row justify-content-md-between justify-content-center">
            <div class="d-flex flex-column col-12 col-md-4 my-3 my-md-2 justify-content-center order-1">
                <img class="logo-footer mx-auto" src="assets/img/white_logo_350.png" alt="logo-footer">
                <p class="text-justify text-md-start text-center">
                    {{ "nis_ekspres_info" | translate }}
                </p>
                <div class="row text-md-start text-center">
                    <div class="col social">
                        <a href="https://sr-rs.facebook.com/nisekspres/" target="_blank">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="https://www.instagram.com/nis_ekspres" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=rs.nisekspres.timetable&amp;hl=sr"
                            target="_blank">
                            <i class="fab fa-android"></i>
                        </a>
                        <a href="viber://chat/?number=%2B381654669551">
                            <i class="fab fa-viber"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 my-3 my-md-2 order-3">
                <h4 class="text-md-start text-center">{{ "informations" | translate }}</h4>
                <div class="d-flex flex-column text-md-start text-center">
                    <a (click)="open('popup_about_us')">{{ "about_us" | translate }}</a>
                    <a (click)="open('rights_obligations_popup')">{{ "terms_of_usage_and_sales" | translate }}</a>
                    <a (click)="open('privacy_policy_popup')">{{ "privacy_policy" | translate }}</a>
                    <a (click)="open('general_terms_and_conditions_popup')">{{ "general_terms_and_conditions" |
                        translate }}</a>
                </div>
            </div>
            <div class="col-12 col-md-3 my-3 my-md-2 order-2">
                <h4 class="text-md-start text-center">{{ "customer_service" | translate }}</h4>
                <div class="d-flex flex-column text-md-start text-center">
                    <a (click)="open('informations_and_help_popup')">{{
                        "informations_and_help" | translate }}</a>
                    <a (click)="open('faq_popup')">{{ "faq"| translate}}</a>
                    <a target="_blank"
                        href="{{getLanguage() == 'en' ? './assets/pdf/Buying Tickets Online.pdf' : './assets/pdf/Uputstvo Internet prodaja.pdf'}}">{{"how_to_buy"
                        | translate }}</a>
                    <a target="_blank" href="./assets/pdf/UputstvoEkspresPlus.pdf">{{ "how_to_use_loyalty_card" |
                        translate }}</a>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-around" style="margin-top: 100px;">
            <div
                class="col-md-4 col-sm-4 col-xs-12 xs-text-align-center sm-text-align-right md-text-align-right footer-image-container">
                <img height="30" src="../../assets/img/payment/cards/Visa/visa.png" />&nbsp;
                <img height="30" src="../../assets/img/payment/cards/MasterCard Maestro/maestro.png" />&nbsp;
                <img height="30" src="../../assets/img/payment/cards/MasterCard Maestro/mastercard.png" />&nbsp;
                <img height="30"
                    src="../../assets/img/payment/cards/American Express/Logotip-American-Express.png" />&nbsp;
                <img height="30" src="../../assets/img/payment/cards/Dina/dina.jpg" />
            </div>
            <div
                class="col-md-4 col-sm-4 col-xs-12 xs-text-align-center sm-text-align-right md-text-align-right footer-image-container">
                <a href="https://www.bancaintesa.rs/pocetna.1.html"><img class="center-block" fxFlex height="30"
                        src="../../assets/img/payment/bank/intesa.png" /></a>
            </div>
            <div
                class="col-md-4 col-sm-4 col-xs-12 xs-text-align-center sm-text-align-left md-text-align-left footer-image-container">
                <a href="http://www.mastercard.com/rs/consumer/credit-cards.html"><img width="60" height="33"
                        src="../../assets/img/payment/cards/SecureCode/sclogo_68x37.gif" /></a>&nbsp;
                <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"><img
                        width="60" height="33"
                        src="../../assets/img/payment/cards/VisaSecure/visa-secure_blu_72dpi.png" /></a>
            </div>
        </div> -->
    </div>
    <!-- <div class="d-flex flex-row align-items-xl-end"
        style="z-index: 1000000;background-color: rgba(19,20,25,0.6);width: auto;height: auto;position: fixed;bottom: 0;right: 40px;border-radius: 5px 5px 5px 5px;box-shadow: 0 0 10px rgba(0,0,0,0.2);text-align: center;margin-right: -22px;margin-bottom: 15px;">
        <a id="scroll-to-top" title="Scroll to top" href="#" style="margin-right: -22px;margin-bottom: 15px;">
            <i class="fas fa-angle-up"></i>
        </a>
    </div> -->
</footer>