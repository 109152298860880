import { DatedJourneyDto } from "src/dtos/dated-journey/dated-journey.dto";

export class JourneyDo {
    StartPoint: string = "";
    EndPoint: string = "";
    DepartureTime: Date = new Date();
    ArrivalTime: Date = new Date();
    DurationTime?: Date;
    IsConnectedJourney: boolean = false;



    constructor(journey: DatedJourneyDto | undefined = undefined) {
        if (journey) {
            const arr = new Date(journey.PassengerArrivalTime)?.getTime();
            const dep = new Date(journey.PassengerDepartureTime)?.getTime();
            this.StartPoint = journey.StartPointName;
            this.EndPoint = journey.EndPointName;
            this.DepartureTime = journey.PassengerDepartureTime;
            this.ArrivalTime = journey.PassengerArrivalTime;

            this.DurationTime = new Date(arr - dep);
            const offset = this.DurationTime.getTimezoneOffset() / 60;
            this.DurationTime.setHours(this.DurationTime.getHours() + offset);
            this.IsConnectedJourney = journey.IsConnectedJourney;
        }
    }
}
