import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AgencyReportFilterDto } from 'src/dtos/agency/agency-report-filter.dto';
import { AgencyReportDto } from 'src/dtos/agency/agency-report-view.dto';
import { AgencyTicketCreateDto } from 'src/dtos/agency/agency-ticket-create-dto';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';
import { TicketVerifyFilterDto } from 'src/dtos/ticket/ticket-verify-filter.dto';
import { TicketVerifyViewDto } from 'src/dtos/ticket/ticket-verify-view.dto';
import { TicketViewDto } from 'src/dtos/ticket/ticket-view.dto';
import { TicketDto } from 'src/dtos/ticket/ticket.dto';
import { WebApiBaseService } from './webapi-base.service';

@Injectable()
export class WebapiTicketService extends WebApiBaseService {
  baseUrl = Constants.BASE_URL + "Ticket";
  constructor(private injector: Injector) {
    super(injector);
  }

  createTicketForAgency(data: AgencyTicketCreateDto): Observable<TicketViewDto[]> {
    return this.http.post<TicketViewDto[]>(this.baseUrl + "/Agency/Tickets", data, this.getHttpParams());
  }

  downloadAgencyTicket(ticketId: string): Observable<any> {
    return this.download(this.baseUrl + "/Agency/DownloadTicket/" + ticketId, this.getHttpParams());
  }

  getAgencyReport(filter: AgencyReportFilterDto): Observable<AgencyReportDto[]> {
    return this.http.post<AgencyReportDto[]>(this.baseUrl + "/Agency/Report", filter, this.getHttpParams());
  }


  cancelAgencyTicket(ticketId: string): Observable<ResponseTemplateDto<boolean>> {
    return this.http.get<ResponseTemplateDto<boolean>>(this.baseUrl + "/Agency/CancelTicket/" + ticketId, this.getHttpParams());
  }

  getOpenJourneyTickets(): Observable<TicketVerifyViewDto[]> {
    return this.http.get<TicketVerifyViewDto[]>(this.baseUrl + "/OpenRoundtripTickets", this.getHttpParams());
  }

  verifyTicket(data: TicketVerifyFilterDto): Observable<ResponseTemplateDto<boolean>> {
    return this.http.post<ResponseTemplateDto<boolean>>(this.baseUrl + "/Verify", data, this.getHttpParams());
  }

  getForwardTicket(ticketId: string): Observable<TicketDto> {
    return this.http.get<TicketDto>(this.baseUrl + "/ForwardTicket/" + ticketId, this.getHttpParams());
  }

}
