import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { SafeHtmlPipe } from 'src/pipes/safe-html.pipe';
import { PassengerNavigationViewDto } from 'src/dtos/users/passenger-navigation-view.dto';
import { UserViewDto } from 'src/dtos/users/user-view.dto';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, SafeHtmlPipe],
  selector: 'multiride-passenger-list',
  templateUrl: './multiride-passenger-list.page.html',
  styleUrls: ['./multiride-passenger-list.page.scss']
})
export class MultiridePassengerListPage extends BaseImports implements OnInit, OnDestroy {
  userData!: UserViewDto;
  subscriptions: Subscription[] = [];
  constructor(private injector: Injector) {
    super(injector);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(this.webapiIdentityService.getProfile().subscribe((res) => {
      this.userData = res;
    }));
  }

  isPassExists(pinfo: PassengerNavigationViewDto) {
    return (pinfo.PassengerPassId != null && pinfo.PassengerPassId != '');
  }

  details(pinfo: PassengerNavigationViewDto) {
    this.routerService.navigate("multiride-create/" + pinfo.PassengerPassId);
  }
}
