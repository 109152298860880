import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injector } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import { Constants } from "src/app/app.constants";
import { AuthenticationService } from "./authentication.service";
import { LocalizationService } from "./localization.service";

export class WebApiBaseService {
  http: HttpClient;
  authenticationService: AuthenticationService;
  constructor(injector: Injector) {
    this.http = injector.get(HttpClient);
    this.authenticationService = injector.get(AuthenticationService);
  }

  getHttpParams(showLoader = true, params?: any, body?: any) {
    let httpParams: HttpParams = new HttpParams();
    let headers = new HttpHeaders();

    if (showLoader)
      headers = headers?.append(Constants.SHOW_LOADER, showLoader.toString());
    if (params) {
      (Object.keys(params))
        .forEach((param) => {
          if (params[param] != undefined && !Number.isNaN(params[param])) {
            if (params[param] instanceof Date) {
              httpParams = httpParams.append(param, (params[param] as Date).toISOString())
            }
            else
              httpParams = httpParams.append(param, params[param].toString())
          }
        });
    }
    return {
      params: httpParams,
      headers: headers,
      body: body
    };
  }

  getDownloadHttpParams(showLoader = true, params?: any, body?: any) {
    let httpParams: HttpParams = new HttpParams();
    let headers = new HttpHeaders();

    if (showLoader)
      headers = headers?.append(Constants.SHOW_LOADER, showLoader.toString());
    if (params) {
      (Object.keys(params))
        .forEach((param) => {
          if (params[param] != undefined && !Number.isNaN(params[param])) {
            if (params[param] instanceof Date) {
              httpParams = httpParams.append(param, (params[param] as Date).toISOString())
            }
            else
              httpParams = httpParams.append(param, params[param].toString())
          }
        });
    }
    return {
      params: httpParams,
      headers: headers,
      body: body,
      responseType: "blob"
    };
  }


  download(requestUrl: string, body: any = null, requestOptions = {}, showLoader = true): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    let headers = new HttpHeaders();
    requestOptions = {
      headers: headers,
      params: httpParams,
      body: null,
      responseType: "blob"
    };

    if (showLoader) {
      headers = headers?.append(Constants.SHOW_LOADER, showLoader.toString());
    }

    return this.http.post(requestUrl, body, requestOptions);
  }
}