<div class="d-flex justify-content-end">

    <div class="remainig-time section text-nowrap ">
        <div class="time">{{time}}</div>
        <div class="desc font-12">{{ "reservation_expires_on" | translate }}:
            <ngb-progressbar class="mb-3" type="dark" [value]="timeProgress" />
        </div>
    </div>
</div>
<div class="title mt-4 margin-bottom-30">
    <h1>{{'purchase_confirmation' | translate}}</h1>
</div>
<div class="container px-4">
    <div
        class="col-12 col-md-9  section d-flex flex-column flex-md-row justify-content-center justify-content-md-around mx-auto">
        <div class="item-buyer">
            <div class="fw-bolder fs-5 mb-2">{{ "buyer" | translate }}</div>
            <div *ngIf="user">
                <div><span class="fw-bolder">{{ "email" | translate }}:</span> {{user.Email}}</div>
                <div><span class="fw-bolder">{{ "name" | translate }}:</span> {{user.Name}}</div>
                <div><span class="fw-bolder">{{ "address" | translate }}:</span> {{user.Address}}</div>
                <div><span class="fw-bolder">{{ "phone" | translate }}:</span> {{user.Phone}}</div>
            </div>
            <div *ngIf="!user" class="details">
                <div><span class="fw-bolder">{{ "email" | translate }}:</span> {{ticket.Email}}</div>
            </div>
        </div>
        <div class=" item-seller mt-4 mt-md-0">
            <div class="fw-bolder fs-5 mb-2">{{ "seller" | translate }}</div>
            <div class="details">
                <div>Niš-ekspres doo Niš</div>
                <!--<div>{{ "company_address" | translate }}</div>-->
                <div>Stevana Sinđelića 59</div>
                <div>18000 Čamurlija, Srbija</div>
                <div>+381 18 255 177</div>
                <div>+381 18 255 666</div>
                <div>http://www.nis-ekspres.rs</div>
            </div>
        </div>
    </div>

    <div class="mt-5 d-none d-md-flex">
        <table class="table">
            <thead>
                <tr>
                    <th>{{ "route_name" | translate }}</th>
                    <th>{{ "departure" | translate }}</th>
                    <th>{{ "arrival" | translate }}</th>
                    <th>{{ "price_per_piece" | translate }}</th>
                    <th>{{ "quantity" | translate }}</th>
                    <th *ngIf="ticket.LoyaltyAmount">{{ "loyalty" | translate }}</th>
                    <th>{{ "price" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let res of ticket.Reservations" class="align-middle">
                    <td>{{res.StartPointName}} - {{res.EndPointName}}</td>
                    <td class="lh-1">
                        <strong class="font-18">{{res.DepartureTime | date: 'HH:mm' }}</strong><br />
                        <span class="font-14">{{res.DepartureTime | date: 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="lh-1">
                        <strong class="font-18">{{res.ArrivalTime | date: 'HH:mm' }}</strong><br />
                        <span class="font-14">{{res.ArrivalTime | date: 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="fw-bolder">{{res.Price | number : '1.2-2'}} <span class="font-12">RSD</span></td>
                    <td class="fw-bolder">{{ticket.Quantity}}</td>
                    <td class="fw-bolder" *ngIf="res.LoyaltyAmount">{{res.LoyaltyAmount}} <span
                            class="font-12">RSD</span></td>
                    <td class="fw-bolder">{{(res.Price * ticket.Quantity + (res.LoyaltyAmount != null?
                        res.LoyaltyAmount: 0)) |
                        number : '1.2-2' }} <span class="font-12">RSD</span></td>
                </tr>
                <tr class="table-total">
                    <td colspan="5" *ngIf="!ticket.LoyaltyAmount" class="text-right font-18 fw-bold"><strong>{{"total" |
                            translate}}:
                        </strong></td>
                    <td colspan="6" *ngIf="ticket.LoyaltyAmount" class="text-right font-18 fw-bold">
                        <strong>{{"total" | translate}}:
                        </strong>
                    </td>

                    <td class="text-right font-18 fw-bolder px-0 text-center"><span> {{ ticket.Amount }} </span>
                        <span class="font-12">RSD</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mt-5 d-md-none sm-table-view">
        <div *ngFor="let res of ticket.Reservations" class="mt-5 ">
            <div class="feild">
                <div class="fw-bolder label">{{ "route_name" | translate }}:</div>
                <div class="value">{{res.StartPointName}} - {{res.EndPointName}}</div>
            </div>
            <div class="feild">
                <div class="fw-bolder label">{{ "departure" | translate }}:</div>
                <div class="value">{{res.DepartureTime | date: 'HH:mm' }}</div>
            </div>
            <div class="feild">
                <div class="fw-bolder label">{{ "arrival" | translate }}:</div>
                <div class="value">{{res.ArrivalTime | date: 'HH:mm' }}</div>
            </div>
            <div class="feild">
                <div class="fw-bolder label">{{ "price_per_piece" | translate }}:</div>
                <div class="value">{{res.Price | number : '1.2-2'}} <span class="font-12">RSD</span></div>
            </div>
            <div class="feild">
                <div class="fw-bolder label">{{ "quantity" | translate }}:</div>
                <div class="value">{{ ticket.Quantity }}</div>
            </div>

            <div class="feild" *ngIf="res.LoyaltyAmount">
                <div class="fw-bolder label">{{ "price_per_piece" | translate }}:</div>
                <div class="value">{{res.LoyaltyAmount}} <span class="font-12">RSD</span></div>
            </div>
            <div class="feild">
                <div class="fw-bolder label">{{ "price" | translate }}:</div>
                <div class="value">{{(res.Price * ticket.Quantity + (res.LoyaltyAmount != null?
                    res.LoyaltyAmount: 0)) |
                    number : '1.2-2' }} <span class="font-12">RSD</span></div>
            </div>
        </div>
    </div>

    <div class="row margin-top-30 justify-content-center">
        <div class="form-group d-flex flex-nowrap justify-content-center">
            <input type="checkbox" class="form-check-input mt-1" name="tnc" id="tnc"
                [(ngModel)]="isCheckedTermsAndConditions">
            <a href="javascript:;" class="ms-2 text-black fw-normal cursor-pointer text-underline-onhover"
                (click)="open('rights_obligations_popup')">
                {{ "i_am_informed_about" | translate }}
                {{ "terms_of_purchase_and_payment" | translate }}</a>
        </div>
    </div>
    <div class="mt-3  justify-content-center d-flex flex-row">
        <div class="text-right mx-2">
            <button type="button" class="btn btn-outline-primary" (click)="routerService.back()">
                {{ "back" | translate }}
            </button>
        </div>
        <div class="text-right mx-2">
            <button *ngIf="isAgency" type="submit" class="btn btn-primary" (click)="openAgencyModal()"
                [disabled]="!isCheckedTermsAndConditions" data-bs-toggle="modal" data-bs-target="#agancyTicketModal">
                {{ "buy" | translate }}
            </button>
            <form *ngIf="!isAgency" class="col-6 text-center" ngNoForm method="post" action="{{constants.PAYMENT_URL}}">
                <input type="hidden" name="clientid" [(ngModel)]="ticket.ClientId">
                <input type="hidden" name="amount" [(ngModel)]="ticket.Amount">
                <input type="hidden" name="oid" [(ngModel)]="ticket.OrderId">
                <input type="hidden" name="okUrl" [(ngModel)]="ticket.OkUrl">
                <input type="hidden" name="failUrl" [(ngModel)]="ticket.FailUrl">
                <input type="hidden" name="tranType" [(ngModel)]="ticket.TransactionType">
                <input type="hidden" name="instalment" [(ngModel)]="ticket.Installment">
                <input type="hidden" name="currency" [(ngModel)]="ticket.Currency">
                <input type="hidden" name="rnd" [(ngModel)]="ticket.Rnd">
                <input type="hidden" name="hash" [(ngModel)]="ticket.Hash">
                <input type="hidden" name="storetype" [(ngModel)]="ticket.StoreType">
                <input type="hidden" name="lang" [(ngModel)]="ticket.Lang">
                <input type="hidden" name="hashAlgorithm" [(ngModel)]="ticket.HashAlgorithm">
                <input type="hidden" name="shopurl" [(ngModel)]="ticket.Shopurl">
                <input type="hidden" name="encoding" value="utf-8">
                <div class="text-center">
                    <button class="btn btn-primary" type="submit" [disabled]="!isCheckedTermsAndConditions">
                        {{ "buy" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>