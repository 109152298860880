import { Injectable } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { SharedService } from './shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../components/alert/alert.component';
//Services
//Constants

@Injectable()
export class CommonService {
    loaderArray: string[];

    constructor(private sharedService: SharedService, private modalService: NgbModal) {
        this.loaderArray = [];
    }

    showLoader() {
        if (this.loaderArray.length == 0) {
            this.sharedService.broadcast(Constants.EV_SPINNER_STATE_CHANGED, true);
        }
        this.loaderArray.push("laod");
    }

    hideLoader() {
        this.loaderArray.pop();
        if (this.loaderArray.length == 0) {
            this.sharedService.broadcast(Constants.EV_SPINNER_STATE_CHANGED, false);
        }
    }

    formatDateTime(date: Date, inclueTime: boolean = true): string {
        var d = new Date(date);
        var year = d.getFullYear() + ".";
        var month = (d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) + "." : (d.getMonth() + 1) + ".";
        var day = d.getDate() < 10 ? "0" + d.getDate() + "." : d.getDate() + ".";

        var time = "";
        if (inclueTime) {
            time = " " + (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) + ":";
            time += (d.getMinutes() < 10 ? "0" : "") + d.getMinutes() + "h";
        }

        return day + month + year + time;
    }

    formatDate(date: Date, inclueTime: boolean = true, separator: string = ''): string {
        var d = new Date(date);
        var year = d.getFullYear() + "";
        var month = (d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) + "" : (d.getMonth() + 1) + "";
        var day = d.getDate() < 10 ? "0" + d.getDate() + "" : d.getDate() + "";
        var time = "";
        if (inclueTime) {
            time += (separator != '' ? " " : '') +
                (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
                (separator != '' ? ":" : '') +
                (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
        }

        return year + separator + month + separator + day + time;
    }

    getDateFromFormatedDate(dateStr: string): Date {
        var date = new Date();
        date.setFullYear(<number><unknown>dateStr.substr(0, 4), <number><unknown>dateStr.substr(4, 2), <number><unknown>dateStr.substr(6, 2));
        var timeStr = dateStr.substr(8);
        if (timeStr.length > 0) {
            date.setHours(<number><unknown>timeStr.substr(0, 2));
            date.setMinutes(<number><unknown>timeStr.substr(2, 2));
        }
        return date;
    }

    normalizeLatinText(text: string) {
        text = text.replace(/š/g, 's').replace(/ć/g, 'c').replace(/č/g, 'c').replace(/ž/g, 'z').replace(/đ/g, 'dj');
        text = text.replace(/Š/g, 'S').replace(/Ć/g, 'C').replace(/Č/g, 'C').replace(/Ž/g, 'Z').replace(/Đ/g, 'Dj');
        return text;
    }

    alertWarning(text: string) {
        const ref = this.modalService.open(AlertComponent, { size: 'md', scrollable: true });
        ref.componentInstance.warrning(text);
    }

    alertError(text: string) {
        const ref = this.modalService.open(AlertComponent, { size: 'md', scrollable: true });
        ref.componentInstance.error(text);
    }

    alert(text: string) {
        const ref = this.modalService.open(AlertComponent, { size: 'md', scrollable: true });
        ref.componentInstance.info(text);
    }
}
