import { Injectable } from "@angular/core";
//Constants
import { Constants } from 'src/app/app.constants';
import { MultirideTicketDo } from "src/dos/multiride/multiride-ticket.do";
import { TicketCreateDo } from "src/dos/ticket-create.do";
import { TicketVerifyViewDto } from "src/dtos/ticket/ticket-verify-view.dto";
import { TicketViewDto } from "src/dtos/ticket/ticket-view.dto";
import { UserViewDto } from "src/dtos/users/user-view.dto";
//Service
import { SharedService } from "./shared.service";


@Injectable()
export class StorageService {
    private _agencyTickets: TicketViewDto[] | null = null;
    private _openJourneyTickets: TicketVerifyViewDto | null = null;
    constructor(private sharedService: SharedService) { }

    getUserData(): UserViewDto | null {
        var data = localStorage.getItem("user_data");
        if (data) {
            return <UserViewDto>JSON.parse(data);
        }
        return null;
    }

    setUserData(userData: UserViewDto) {
        localStorage.setItem("user_data", JSON.stringify(userData));
    }

    getUsername() {
        var data = this.getUserData();
        if (data == null || data == undefined) {
            return null;
        }
        return data.Username;
    }

    getLoyaltyCardId() {
        var data = this.getUserData();
        if (data == null || data == undefined) {
            return null;
        }
        return data.LoyaltyCardId;
    }

    isAgency() {
        var data = this.getUserData();
        if (data == null || data == undefined) {
            return false;
        }
        return data.IsAgency;
    }

    //-------------Ticket----------------
    setTicket(reservation: TicketCreateDo, durationInSeconds: number | null = null) {
        localStorage.setItem('ticket', JSON.stringify(reservation));

        if (durationInSeconds) {
            var now = new Date();
            var resExpirated = new Date(now.getTime() + (durationInSeconds * 1000));
            localStorage.setItem('ticket-expiration', JSON.stringify(resExpirated));
            //this.sharedService.broadcast(Constants.EV_TICKET_CREATED);
        }
    }

    getTicket(): TicketCreateDo | null {
        var data = localStorage.getItem('ticket');
        if (data) {
            return <TicketCreateDo>JSON.parse(data);
        }
        return null;
    }

    clearTicket() {
        localStorage.removeItem('ticket');
        localStorage.removeItem('ticket-expiration');
    }

    getReservationDuration() {
        var data = localStorage.getItem('ticket-expiration');
        if (data == null || data == undefined) {
            return null;
        }
        var _toDate = <string>JSON.parse(data);
        if (_toDate == null || _toDate == undefined) {
            return null;
        }

        var toDate = new Date(_toDate);
        var current = new Date();
        return Math.floor((toDate.getTime() - current.getTime()) / 1000);
    }

    setReservation(reservation: TicketCreateDo) {
        localStorage.setItem('reservation', JSON.stringify(reservation));
    }

    getReservation(): TicketCreateDo | null {
        var data = localStorage.getItem('reservation');
        if (data) {
            return <TicketCreateDo>JSON.parse(data);
        }
        return null;
    }

    clearReservation() {
        localStorage.removeItem('reservation');
    }

    //------------Agency-----------------------------
    setAgencyTickets(data: TicketViewDto[]) {
        this._agencyTickets = data;
    }

    getAgencyTickets() {
        return this._agencyTickets;
    }

    clearAgencyTickets() {
        this._agencyTickets = null;
    }

    //----------OpenJourneyTicket------------
    setOpenJourneyTickets(data: TicketVerifyViewDto) {
        this._openJourneyTickets = data;
    }

    getOpenJourneyTickets(): TicketVerifyViewDto | null {
        return this._openJourneyTickets;
    }

    clearOpenJourneyTickets() {
        this._openJourneyTickets = null;
    }

    //-----------------Multiride ticket
    setMultirideTicket(ticket: MultirideTicketDo) {
        localStorage.setItem('multiride-ticket', JSON.stringify(ticket));
    }

    getMultirideTicket(): MultirideTicketDo | null {
        var data = localStorage.getItem('multiride-ticket');
        if (data) {
            return <MultirideTicketDo>JSON.parse(data);
        }
        return null;
    }

    clearMultirideTicket() {
        localStorage.removeItem('multiride-ticket');
    }
}
