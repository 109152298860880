<div class="d-flex flex-row  align-items-center">
    <div class="d-flex flex-column text-center px-3 ">
        <span class="text-nowrap fw-bolder lh-sm variable-font-size-16">
            {{datedJourney.DepartureTime | date: 'HH:mm':'':lang()}}
        </span>
        <span class="text-nowrap lh-sm variable-font-size-16">
            {{datedJourney.StartPoint}}</span>
        <span class="text-nowrap lh-sm variable-font-size-16" *ngIf="showDate">
            {{datedJourney.DepartureTime | date:'dd. MMM':'':lang()}}
        </span>
    </div>
    <div class=" text-center w-100">
        <div class="variable-font-size-10 journey-duration-time lh-sm">
            {{datedJourney.DurationTime | date: "H'h' mm'min'":'':lang()}}
        </div>
        <div class="variable-font-size-10" *ngIf="datedJourney.IsConnectedJourney">
            {{"connected_journey" | translate}}
        </div>
    </div>
    <div class="text-center d-flex flex-column px-3 min-width-90px">
        <span class="text-nowrap fw-bolder lh-sm variable-font-size-16">
            {{datedJourney.ArrivalTime | date: 'HH:mm':'':lang()}}
        </span>
        <span class="text-nowrap lh-sm variable-font-size-16">
            {{datedJourney.EndPoint}}</span>
        <span class="text-nowrap lh-sm variable-font-size-16" *ngIf="showDate">
            {{datedJourney.ArrivalTime | date: 'dd. MMM':'':lang()}}
        </span>
    </div>
</div>