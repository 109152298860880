import { Component, computed, Injector, Input, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JourneyDo } from 'src/dos/journeys/journeys.do';
import { LocalizationService } from 'src/services/localization.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'departure-description-component',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './departure-description.component.html',
  styleUrls: ['./departure-description.component.scss']
})
export class DepartureDescriptionComponent {
  lang!: Signal<string>;
  @Input() datedJourney: JourneyDo = new JourneyDo();
  @Input() showDate: boolean = false;
  localizationService: LocalizationService;

  constructor(injector: Injector) {
    this.localizationService = injector.get(LocalizationService);
    this.lang = computed(() => {
      return this.localizationService.currentLanguage().LanguageCode_2;
    });
  }
}
