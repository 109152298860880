import { Component, computed, Injector, OnDestroy, signal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyReportDto } from 'src/dtos/agency/agency-report-view.dto';
import { AgencyReportFilterDto } from 'src/dtos/agency/agency-report-filter.dto';
import { BaseImports } from 'src/libs/base-imports';
import { ModalComponent } from 'src/components/modal/modal.component';
import { ModalDo } from 'src/dos/modal.do';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbDateStruct, NgbModal, NgbModule, NgbPaginationConfig, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { Table } from 'src/libs/table';
import { Subscription } from 'rxjs';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';

@Component({
  selector: 'app-agency-report',
  standalone: true,
  imports: [CommonModule, ModalComponent, NgbModule, TranslateModule, FormsModule, NgbDatepickerModule, NgbPaginationModule],
  templateUrl: './agency-report.page.html',
  styleUrls: ['./agency-report.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    NgbPaginationConfig
  ]
})
export class AgencyReportPage extends BaseImports implements OnDestroy {
  subscriptions: Subscription[] = [];
  ToDate: Date = new Date();
  report: Table<AgencyReportDto>;
  FromDate: Date = new Date();


  total: number = 0;
  totalLoyalty: number = 0;
  constructor(private injector: Injector, private modalService: NgbModal, config: NgbPaginationConfig) {
    super(injector);
    this.report = new Table<AgencyReportDto>(1, 10, config);
    this.report.setData([]);
  }

  ngOnInit() {
    this.load();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  load() {
    this.total = 0;
    this.totalLoyalty = 0;
    const form = new Date(this.FromDate);
    form.setHours(- form.getTimezoneOffset() / 60, 0, 0, 0);
    const to = new Date(this.ToDate);
    to.setHours(- form.getTimezoneOffset() / 60, 0, 0, 0);
    var filter: AgencyReportFilterDto = {
      FromDate: form,
      ToDate: to
    };
    this.subscriptions.push(this.webapiTicketService.getAgencyReport(filter).subscribe((res: AgencyReportDto[]) => {
      this.report.setData(res);
      this.report.page = 1;
      this.report._allData.forEach((d) => {
        if (!d.IsCanceled) {
          this.total += d.Price;
          this.totalLoyalty += (d.LoyaltyAmount < 0 ? d.LoyaltyAmount : 0);
        }
      });
      this.totalLoyalty = - this.totalLoyalty;
      this.total -= this.totalLoyalty;
    }));
  }

  download(data: AgencyReportDto) {
    this.subscriptions.push(this.webapiTicketService.downloadAgencyTicket(data.TicketId).subscribe((res) => {
      var link = document.createElement('a');
      link.setAttribute("type", "hidden");
      link.download = 'Karta.pdf';
      link.href = window.URL.createObjectURL(res);
      document.body.appendChild(link);
      link.click();
    }));
  }

  cancel(data: AgencyReportDto) {
    var body: ModalDo = {
      content: this.translateService.instant("ticket_cancelation") + ":" + data.Number + ' ?',
      title: ""
    };

    const ref = this.modalService.open(ModalComponent, { size: 'xl', scrollable: true });
    ref.componentInstance.setText(body);
    ref.componentInstance.isConfirmModal(true);
    ref.result.then(() => {
      this.subscriptions.push(this.webapiTicketService.cancelAgencyTicket(data.TicketId).subscribe((res: ResponseTemplateDto<boolean>) => {
        if (res.IsSuccess) {
          this.commonService.alert(this.translateService.instant("ticket_nr") + ":" + data.Number + " " + this.translateService.instant("succesfully_canceled"));
          this.load();
        }
        else {
          this.commonService.alertError(this.translateService.instant("ticket_nr") + ":" + data.Number + " " + this.translateService.instant("unsuccesfully_canceled") + " " + this.translateService.instant(res.Reason ?? res.ErrorMessage ?? 'error'));
        }
      }));
    });
  }
}
