import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ForgotPasswordDto } from 'src/dtos/forgot-password/forgot-password.dto';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  selector: 'forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  submitted = false;
  data: ForgotPasswordDto = new ForgotPasswordDto();
  form = this.formBuilder.group({
    Email: ['', Validators.required],
  });

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.data = Object.assign(this.data, this.form.value);
    this.subscriptions.push(this.webapiIdentityService.forgotPassword(this.data).subscribe((res) => {
      if (res.IsSuccess) {
        this.commonService.alert(this.translateService.instant("params_are_sent_on_email") + " " + this.data.Email);
        this.routerService.back();
      }
      else {
        this.commonService.alertError("Error: " + this.translateService.instant(res.Reason!));
        this.form.reset();
      }
    }));
  }
}
