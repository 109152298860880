<div class="title margin-bottom-30">
    <h1>Rezervacija</h1>
</div>

<div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="section col-12  col-md-8">
            <div class="d-flex flex-row justify-content-evenly flex-fill">
                <departure-description-component class="journeys-ride-description" [datedJourney]="rideDescription()"
                    [showDate]="true">

                </departure-description-component>
                <div class="d-flex flex-column text-center ms-auto me-1 ms-md-5 my-auto">
                    <span class="fs-5 fw-bolder variable-font-size-20 text-primary">&nbsp;2500 din&nbsp;</span>
                    <s class="journey-original-price variable-font-size-16 align-self-center"><span
                            class="m-1">&nbsp;2600 din&nbsp;</span></s>
                </div>
            </div>
        </div>
        <div class="row margin-top-30 justify-content-center">
            <div class="form-group col-lg-4 col-md-6 col-sm-8 col-12 text-center variable-font-size-16">
                <label>
                    {{ "number_of_tickets" | translate }}
                </label>
                <input type="number" formControlName="Quantity" class="form-control" min="1" max="5" />
            </div>
            <div class="col-12 text-center mt-2 fst-italic">
                * Maksimalni broja rezervacija po nalogu je 5
            </div>
        </div>
        <div class="row mt-5 justify-content-center">
            <div class="col-6 text-right">
                <button type="button" class="btn btn-outline-primary" (click)="routerService.back()">
                    {{ "back" | translate }}
                </button>
            </div>
            <div class="col-6">
                <button type="submit" class="btn btn-primary">Rezervisi</button>
            </div>
        </div>
    </form>
</div>