import { JsonpClientBackend } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { ResponseTemplateDto } from 'src/dtos/response-template.dto';
import { WebApiBaseService } from './webapi-base.service';


@Injectable()
export class WebapiSessionService extends WebApiBaseService {
  baseUrl = Constants.BASE_URL + "Session";
  constructor(private injector: Injector) {
    super(injector);
  }

  isSessionOpen(): Observable<ResponseTemplateDto<boolean>> {
    return this.http.get<ResponseTemplateDto<boolean>>(this.baseUrl + "/IsOpen", this.getHttpParams());
  }
}
