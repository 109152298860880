import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { MultirideTicketDo } from 'src/dos/multiride/multiride-ticket.do';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-multiride-payment-status',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './multiride-payment-status.page.html',
  styleUrls: ['./multiride-payment-status.page.scss']
})
export class MultiridePaymentStatusPage extends BaseImports implements OnInit, OnDestroy {
  isSuccess: boolean = false;
  transId: string = "";
  authCode: string = "";
  orderId: string = "";
  datetime: string = "";
  mdStatus: string = "";
  procReturnCode: string = "";
  response: string = "";
  total: number;
  email: string = "";

  buyerName: string = "";
  buyerAddress: string = "";
  buyerPhone: string = "";

  ticket: MultirideTicketDo = new MultirideTicketDo();
  subscriptions: Subscription[] = [];

  constructor(private injector: Injector) {
    super(injector);
    this.total = 0;
    const ticket = this.storageService.getMultirideTicket();
    if (ticket !== null) {
      this.ticket = ticket;
    }

    this.total = Number(this.ticket.Amount);

    if (this.authenticationService.isAuthenticated()) {
      var userData = this.storageService.getUserData();
      if (userData) {
        this.buyerName = userData.Name;
        this.buyerAddress = userData.Address;
        this.buyerPhone = userData.Phone;
        this.email = userData.Email;
      }
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.isSuccess = (params['isSuccess'] == "false" ? false : true);
      this.transId = params['transId'] == "null" ? "-" : params['transId'];
      this.authCode = params['authCode'] == "null" ? "-" : params['authCode'];
      this.datetime = params['datetime'] == "null" ? "-" : params['datetime'];
      this.orderId = params['orderId'] == "null" ? "-" : params['orderId'];
      this.mdStatus = params['mdStatus'] == "null" ? "-" : params['mdStatus'];
      this.procReturnCode = params['procReturnCode'] == "null" ? "-" : params['procReturnCode'];
      this.response = params['response'] == "null" ? "-" : params['response'];
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.storageService.clearMultirideTicket();
  }
}


