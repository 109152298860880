import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseImports } from 'src/libs/base-imports';
import { SharedDo } from 'src/dos/shared/shared.do';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'spinner-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  providers: []
})
export class SpinnerComponent extends BaseImports implements OnInit, OnDestroy {
  showSpinner: boolean;
  subscriptions: Subscription[] = [];

  constructor(private injector: Injector) {
    super(injector);
    this.showSpinner = false;
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.sharedService.on(this.constants.EV_SPINNER_STATE_CHANGED, this.spinnerStateChanged.bind(this)));
  }

  spinnerStateChanged(data: SharedDo) {
    this.showSpinner = data.Data;
  }
}
