<div class="container mt-5 px-lx-4 px-0 mx-auto">
    <div class="d-flex flex-row flex-wrap justify-content-around mx-3">
        <div class="mx-1 my-2 my-md-1 col-12 col-md-3">
            <div class="input-group">
                <span class="input-group-text pe-1">
                    <img src="../../assets/icons/calendar.svg" width="20" class="me-2">
                    <div class="text-capitalize">
                        {{ "from" | translate }}</div>
                </span>
                <input type="text" ngbDatepicker #dateFrom="ngbDatepicker" [(ngModel)]="FromDate"
                    class="form-control px-1 fw-bolder text-start" (click)="dateFrom.toggle()" readonly>
            </div>
        </div>
        <div class="mx-1 my-2 my-md-1 col-12 col-md-3">
            <div class="input-group">
                <span class="input-group-text pe-1">
                    <img src="../../assets/icons/calendar.svg" width="20" class=" me-2"><span class="text-capitalize">
                        {{ "to" | translate }}</span>
                </span>
                <input type="text" ngbDatepicker #dateTo="ngbDatepicker" [(ngModel)]="ToDate"
                    class="form-control px-1 fw-bolder text-start" (click)="dateTo.toggle()" readonly>
            </div>
        </div>

        <div class="mx-1 my-2 my-md-1 col-12 col-md-3 d-flex justify-content-center mt-3 mt-lg-0 ms-lg-0">
            <button type="button" class="btn btn-primary py-1" (click)="load()">
                <img class="margin-right-5" src="../../assets/icons/search.svg" width="18">
                {{ 'search' | translate }}
            </button>
        </div>
    </div>
    <div class="mt-5 d-none d-lg-flex align-middle">
        <table class="table">
            <thead>
                <tr class="align-middle">
                    <th>{{ "created_at_agency_report" | translate }}</th>
                    <th>{{ "start_point_name_agency_report" | translate }}</th>
                    <th>{{ "ticket_nr_agency_report" | translate }}</th>
                    <th>{{ "is_roundtrip_agency_report" | translate }}</th>
                    <th>{{ "departure_time_agency_report" | translate }}</th>
                    <th>{{ "loyalty" | translate }} RSD</th>
                    <th>{{ "price_agency_report" | translate }} RSD</th>
                    <th>{{ "pt_operator_business_name_agency_report" | translate }}</th>
                    <th>{{ "note_agency_report" | translate }}</th>
                    <th>{{ "action_agency_report" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of report.data" class="align-middle font-14">
                    <td class="lh-1">
                        <span class="font-16">{{row.CreatedAt | date: 'HH:mm' }}</span><br />
                        <span class="font-14">{{row.CreatedAt | date: 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="text-nowrap">{{row.StartPointName}} - {{row.EndPointName}}</td>
                    <td>{{row.Number}}</td>
                    <td>{{(row.IsRoundtrip ? 'yes' : 'no') | translate}}</td>
                    <td class="lh-1">
                        <span class="font-16">{{row.DepartureTime | date: 'HH:mm' }}</span><br />
                        <span class="font-14">{{row.DepartureTime | date: 'dd.MM.yyyy'}}</span>
                    </td>
                    <td>{{ (row.LoyaltyAmount < 0 && !row.IsCanceled ? row.LoyaltyAmount : '' ) | number : '1.2-2'
                            }}</td>
                    <td>{{row.Price + (row.LoyaltyAmount < 0 ? row.LoyaltyAmount : 0) | number : '1.2-2' }}</td>
                    <td class="text-nowrap">{{row.PTOperatorBusinessName}}</td>
                    <td>{{row.Note}}</td>
                    <td>
                        <div class="d-flex flex-row flex-nowrap">
                            <button *ngIf="!row.IsCanceled && !row.IsRoundtripWay && !row.IsConnectedTicket"
                                class="btn btn-action" (click)="cancel(row)"><span class="fa fa-trash"></span></button>
                            <button *ngIf="!row.IsCanceled && !row.IsRoundtripWay && !row.IsConnectedTicket"
                                class="btn btn-action" (click)="download(row)"><span
                                    class="fa fa-download"></span></button>
                            <span *ngIf="row.IsCanceled">{{ "is_canceled_agency_report" | translate}}</span>

                        </div>
                    </td>
                </tr>
                <tr *ngIf="report.count == 0" class="align-middle text-center fw-bold font-18">
                    <td colspan="10" class="text-primary">
                        {{'no_results' | translate}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-none d-lg-flex justify-content-center">
        <ngb-pagination *ngIf="report.data.length > 0" [collectionSize]="report.count" [(page)]="report.page"
            (pageChange)="report.refreshData()" />
    </div>
    <div class="my-4">
        <div *ngIf="report.count > 0" class="text-center margin-top-5"><strong>
                {{ "sum_agency_report" | translate}}: {{total | number : '1.2-2'}} RSD</strong>
        </div>
        <div *ngIf="report.count > 0" class="text-center margin-top-5 font-14">
            {{ "loyalty" | translate}}: {{totalLoyalty | number : '1.2-2'}} RSD
        </div>
    </div>

    <div class="d-block d-lg-none">
        <div *ngFor="let row of report.data" class="d-flex d-md-none justify-content-center" class="align-middle lh-2">

            <div class="section m-4">
                <div class="text-nowrap  font-20 fw-bold">{{row.StartPointName}} - {{row.EndPointName}}
                </div>
                <div class="row m-2">
                    <div class="col-6 fw-bolder text-left">
                        <div>{{ "created_at_agency_report" | translate }}</div>
                        <div>{{ "ticket_nr_agency_report" | translate }}</div>
                        <div>{{ "is_roundtrip_agency_report" | translate }}</div>
                        <div>{{ "departure_time_agency_report" | translate }}</div>
                        <div>{{ "loyalty" | translate }} RSD</div>
                        <div>{{ "price_agency_report" | translate }} RSD</div>
                        <div>{{ "pt_operator_business_name_agency_report" | translate }}</div>
                        <div>{{ "note_agency_report" | translate }}</div>
                    </div>
                    <div class="col-6 text-left">
                        <div>{{row.CreatedAt | date: 'dd.MM.yyyy. HH:mm' }}</div>
                        <div>{{row.Number}}</div>
                        <div>{{(row.IsRoundtrip ? 'yes' : 'no') | translate}}</div>
                        <div>{{row.DepartureTime | date: 'dd.MM.yyyy. HH:mm' }}</div>
                        <div>{{ (row.LoyaltyAmount < 0 && !row.IsCanceled ? row.LoyaltyAmount : '' ) | number : '1.2-2'
                                }}</div>
                                <div>{{row.Price + (row.LoyaltyAmount < 0 ? row.LoyaltyAmount : 0) | number : '1.2-2'
                                        }}</div>
                                        <div class="text-nowrap">{{row.PTOperatorBusinessName}}</div>
                                        <div>{{row.Note}}</div>

                                </div>
                        </div>


                        <div>
                            <div class="d-flex flex-row flex-nowrap justify-content-end">
                                <button *ngIf="!row.IsCanceled && !row.IsRoundtripWay && !row.IsConnectedTicket"
                                    class="btn btn-action" (click)="cancel(row)"><span
                                        class="fa fa-trash"></span></button>
                                <button *ngIf="!row.IsCanceled && !row.IsRoundtripWay && !row.IsConnectedTicket"
                                    class="btn btn-action" (click)="download(row)"><span
                                        class="fa fa-download"></span></button>
                                <span *ngIf="row.IsCanceled">{{ "is_canceled_agency_report" | translate}}</span>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="d-flex d-lg-none justify-content-center" *ngIf="report.count !== report.data.length">
                    <button class="btn btn-primary mx-auto" (click)="report.loadMore()">
                        Ucitaj jos
                    </button>
                </div>
                <div *ngIf="report.count == 0" class="align-middle text-center fw-bold font-18 text-primary">
                    {{'no_results' | translate}}</div>
            </div>

        </div>