<div class="title margin-bottom-30">
    <h1>{{ "my_tickets" | translate }}</h1>
</div>
<div class="container px-4">
    <div class="mt-5 d-none d-md-flex align-middle">
        <table class="table">
            <thead>
                <tr class="align-middle">
                    <th>{{ "created_at_agency_report" | translate }}</th>
                    <th>{{ "start_point_name_agency_report" | translate }}</th>
                    <th>{{ "price_agency_report" | translate }} RSD</th>
                    <th>{{ "valid_until" | translate }}</th>
                    <th>{{ "action_agency_report" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of tickets.data" class="align-middle font-14">
                    <td class="lh-1">
                        <span class="font-16">{{row.CreatedAt | date: 'HH:mm' }}</span><br />
                        <span class="font-14">{{row.CreatedAt | date: 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="text-nowrap">{{row.RideDescription}}</td>
                    <td>{{row.Price | number : '1.2-2'}}</td>
                    <td>{{row.VerificationDeadline | date: 'dd.MM.yyyy HH:mm'}}</td>
                    <td>
                        <div class="d-flex flex-row flex-nowrap">
                            <button class="btn btn-action mx-auto" (click)="next(row)"><span
                                    class="fas fa-calendar-plus"></span></button>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="tickets.count == 0" class="align-middle text-center fw-bold font-18">
                    <td colspan="5" class="text-primary">
                        {{'no_return_tickets_with_open_journey' | translate}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-none d-md-flex justify-content-center">
        <ngb-pagination *ngIf="tickets.data.length > 0" [collectionSize]="tickets.count" [(page)]="tickets.page"
            (pageChange)="tickets.refreshData()" />
    </div>
    <div class="d-block d-md-none">
        <div *ngFor="let row of tickets.data" class="d-flex d-md-none justify-content-center" class="align-middle lh-2">

            <div class="section m-4">
                <div class="text-nowrap  font-20 fw-bold">{{row.RideDescription}}
                </div>
                <div class="row m-2">
                    <div class="col-6 fw-bolder text-left">
                        <div>{{ "created_at_agency_report" | translate }}</div>
                        <div>{{ "start_point_name_agency_report" | translate }}</div>
                        <div>{{ "price_agency_report" | translate }} RSD</div>
                        <div>{{ "valid_until" | translate }}</div>
                    </div>
                    <div class="col-6 text-left">
                        <div>{{row.CreatedAt | date: 'dd.MM.yyyy HH:mm'}}</div>
                        <div class="text-nowrap">{{row.RideDescription}}</div>
                        <div>{{row.Price | number : '1.2-2'}}</div>
                        <div>{{row.VerificationDeadline | date: 'dd.MM.yyyy HH:mm'}}</div>
                    </div>
                </div>


                <div>
                    <div class="d-flex flex-row flex-nowrap justify-content-end">
                        <button class="btn btn-action" (click)="next(row)"><span
                                class="fas fa-calendar-plus"></span></button>
                    </div>
                </div>
            </div>

        </div>

        <div class="d-flex d-md-none justify-content-center" *ngIf="tickets.count !== tickets.data.length">
            <button class="btn btn-primary mx-auto" (click)="tickets.loadMore()">
                Ucitaj jos
            </button>
        </div>
        <div *ngIf="tickets.count == 0" class="align-middle text-center fw-bold font-18">
            {{'no_return_tickets_with_open_journey' | translate}}</div>
    </div>
</div>