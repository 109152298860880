import { Injectable, Injector } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import {
  map,
  catchError,
  distinctUntilChanged,
  filter,
  concatMap,
} from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { SignInDto } from 'src/dtos/sign-in/sign-in.dto';
import { AuthenticationResponseDto } from 'src/dtos/authentication-response/authentication-response.dto';
import { CommonService } from 'src/services/common.service';
import { SharedService } from 'src/services/shared.service';
import { RouterService } from 'src/services/router.service';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable()
export class AuthenticationService {
  key: string;
  commonService: CommonService;
  storageService: StorageService;
  private loggedIn!: boolean;

  constructor(
    private httpService: HttpClient,
    private injector: Injector,
    private sharedService: SharedService,
    private routerService: RouterService
  ) {
    this.key = 'login-data';
    this.commonService = this.injector.get(CommonService);
    this.storageService = this.injector.get(StorageService);
  }

  login(signIn: SignInDto) {
    let url = `${Constants.API_ENDPOINT}token`;
    let body =
      'username=' +
      signIn.Email +
      '&password=' +
      signIn.Password +
      '&grant_type=password';

    return this.handleLoginLogic(url, body);
  }

  private handleLoginLogic(url: string, body: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let options = { headers: headers };
    this.commonService.showLoader();
    return this.httpService
      .post<Response>(url, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.mapData(res);
        }),
        catchError((err) => {
          return this.mapError(err);
        })
      )
      .pipe(
        concatMap((res: any) => {
          this.routerService.navigate(Constants.DF_PAGE);
          this.storageService.clearTicket();
          this.sharedService.broadcast(Constants.EV_LOGIN_STATE_CHANGED, true);
          return this.isTokenActive();
        })
      );
  }

  private customMapError(msg: String) {
    this.commonService.hideLoader();

    //regex solution
    this.commonService.alertError(msg?.replace(/_/g, ' '));
    return {};
  }

  private mapData(res: Response) {
    this.commonService.hideLoader();
    let body: any,
      text = true,
      data: any = res;
    body = res;
    if (text) {
      if (body.Data != undefined) {
        data = body.Data;
      }
    }

    localStorage.setItem(this.key, JSON.stringify(data));
    return body || {};
  }

  // NOTE: This code should also be put in HandleError function in connection service.
  private mapError(res: HttpErrorResponse | any) {
    this.commonService.hideLoader();
    let errMsg: string = '';
    if (res instanceof Response) {
      let body = '';

      res.text().then((value) => {
        body = value || '';
      });
    }
    if (res instanceof HttpErrorResponse) {
      errMsg = res.error.error_description;
    } else {
      errMsg = res?.message ?? res.toString();
    }

    if (errMsg.indexOf('"isTrusted": true') > -1) {
      this.commonService.alertError('Webapi is offline');
    }

    if (res.status == 400) {
      this.commonService.alertError(errMsg);
    }

    return throwError(res);
  }

  logout(): Observable<boolean> {
    localStorage.removeItem('user_data');
    localStorage.removeItem('email');
    localStorage.removeItem(this.key);

    this.storageService.clearTicket();
    this.sharedService.broadcast(Constants.EV_LOGIN_STATE_CHANGED, false);
    //This should be replaced with real api call to invalidate tokens
    return new Observable((observer) => {
      observer.next(true);
    });
  }

  getAccessToken(): string | null {
    var data = <AuthenticationResponseDto>(
      JSON.parse(localStorage.getItem(this.key) || '{}')
    );
    if (data == null || data == undefined) {
      return null;
    }
    return data.access_token;
  }

  getRefreshToken(): string | null {
    var data = <AuthenticationResponseDto>(
      JSON.parse(localStorage.getItem(this.key) || '{}')
    );
    if (data == null || data == undefined) {
      return null;
    }
    return data.access_token;
  }

  refreshToken(): Observable<any> {
    let accessToken = this.getAccessToken();
    if (accessToken == '' || accessToken == null || accessToken == undefined)
      return of(true);

    let url = `${Constants.API_ENDPOINT}connect/token`;
    let body =
      'grant_type=refresh_token&client_id=' +
      Constants.CLIENT_ID +
      '&client_secret=' +
      Constants.CLIENT_SECRETS +
      '&refresh_token=' +
      this.getRefreshToken();
    return this.httpService.post<Response>(url, body).pipe(
      map((res: Response) => {
        let body: any;
        body = res;
        if (body.IsSuccess) {
          return this.mapData(res);
        } else {
          this.logout().subscribe({
            next: () => {
              this.routerService.navigate('sign-in');
            },
            error: () => {
              this.commonService.alertError('Something went wrong');
            },
          });
        }
      }),
      catchError((err) => {
        this.logout().subscribe({
          next: () => {
            this.routerService.navigate('sign-in');
          },
          error: () => {
            this.commonService.alertError('Something went wrong');
          },
        });
        // return throwError("Problem with refresh token.");
        return throwError(() => new Error('Invalid tokens.'));
      })
    );
  }

  isAuthenticated(): boolean {
    var data = <AuthenticationResponseDto>(
      JSON.parse(localStorage.getItem(this.key) || '{}')
    );

    if (data == null || data == undefined) {
      return false;
    }

    if (data.access_token == null || data.access_token == undefined) {
      return false;
    }
    return true;
  }

  generateRequestHeaders(): HttpHeaders {
    var headers = null;
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getAccessToken(),
    });
    return headers;
  }

  isTokenActive() {
    var _headers = this.generateRequestHeaders();
    var requestOptions = { headers: _headers };

    return this.httpService
      .get(
        Constants.API_ENDPOINT + Constants.API_VERSION + 'Identity',
        requestOptions
      )
      .pipe(
        map((res: any) => {
          this.storageService.setUserData(res);
          return true;
        }),
        catchError((err) => {
          if (err.status == 401) {
            return of(false);
          } else {
            return of(true);
          }
        })
      );
  }
}
